import React, { Component } from "react";
import { connect } from "react-redux";
import { updateUserProfile } from "../../redux";
import { Formik } from "formik";
import * as Yup from "yup";

// Import Services

// Import Utils

// Import Components
import LNFloatingInput from "../common/LNFloatingInput/LNFloatingInput";

// Import Images
import ArrowRight from "../../img/icons/black/arrow-right.svg";

// Import CSS
import "./editPersonalInfo.css";

class EditPersonalInfo extends Component {
	render() {
		// Validations
		const ValidationSchema = Yup.object().shape({
			first_name: Yup.string()
				.max(255, "Too Long!")
				.required("Required field"),
			middle_name: Yup.string().max(255, "Too Long!"),
			last_name: Yup.string()
				.max(255, "Too Long!")
				.required("Required field"),
		});

		const { userData, updateUserProfile, reset_view_func } = this.props;
		const { user } = userData;

		// Check if loading
		if (userData.loading) return <div>Loading...</div>;

		// Render
		return (
			<div>
				<div className="d-flex flex-row align-items-center">
					<div
						className="profile-page-link"
						onClick={() => {
							reset_view_func();
						}}>
						Profile Page
					</div>
					<img src={ArrowRight} alt="Arrow Right" className="profile-page-arrow" />
					<div className="profile-page-link-name">Personal Information</div>
				</div>
				<div className="row mt-5">
					<div className="col-6 offset-2">
						<div className="profile-section-title">Personal Information</div>
						<Formik
							initialValues={user}
							validationSchema={ValidationSchema}
							onSubmit={async (values, { setSubmitting, setErrors }) => {
								setSubmitting(true);
								const postData = {
									first_name: values.first_name,
									middle_name: values.middle_name,
									last_name: values.last_name,
								};
								Object.keys(postData).forEach(key => {
									if (!postData[key]) delete postData[key];
								});

								// Send Update request
								try {
									await updateUserProfile(postData);
									setSubmitting(false);
								} catch (error) {
									setErrors(error.response.data);
									setSubmitting(false);
								}
								// Send back to main profile page
								reset_view_func();
							}}>
							{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
								<form onSubmit={handleSubmit}>
									<div>
										<LNFloatingInput
											size="lg"
											type="text"
											optional={false}
											name="first_name"
											value={values.first_name}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="First Name"
											error={
												touched.first_name && errors.first_name ? `* ${errors.first_name}` : ""
											}
										/>

										<LNFloatingInput
											size="lg"
											type="text"
											optional={true}
											name="middle_name"
											value={values.middle_name}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="Middle Name"
											error={
												touched.middle_name && errors.middle_name
													? `* ${errors.middle_name}`
													: ""
											}
										/>

										<LNFloatingInput
											size="lg"
											type="text"
											optional={false}
											name="last_name"
											value={values.last_name}
											onChange={handleChange}
											onBlur={handleBlur}
											placeholder="Last Name"
											error={touched.last_name && errors.last_name ? `* ${errors.last_name}` : ""}
										/>
									</div>
									<div
										className="btn btn-lg btn-black w-100 mt-5"
										onClick={handleSubmit}
										disabled={isSubmitting}>
										Save
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		userData: state.user.userData,
	};
}
function mapDispatchToProps(dispatch) {
	return {
		updateUserProfile: updated_data => dispatch(updateUserProfile(updated_data)),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(EditPersonalInfo);
