import React, { useEffect, useState } from 'react'

// type AutomationLoanTypeTagProps = {
//   value: string;
//   style?: React.CSSProperties;
// };

const AutomationLoanTypeTag = ({ value }) => {
  const [color, setColor] = useState('#DEDEDE')
  const [textColor, setTextColor] = useState('#1A1A1A')
  const [displayValue, setDisplayValue] = useState('')
  useEffect(() => {
    if (value === 'active') {
      setDisplayValue('Active');
      setTextColor("#1A1A1A");
      setColor('#D1ECE2');
    }
    else if (value === 'inactive') {
      setDisplayValue('Inactive');
      setTextColor("#1A1A1A");
      setColor('#DEDEDE');
    }
    else if (value === 'crypto') {
      setDisplayValue('Crypto Loan');
      setTextColor("#FFF");
      setColor('#6462FF');
    }
    else if (value === 'cash') {
      setDisplayValue('Cash Loan');
      setTextColor("#FFF");
      setColor('#089C8E');
    }
    // else if (value === 'both') {
    //   setDisplayValue('Any Loan');
    //   setTextColor("#FFF");
    //   setColor('#6462FF');
    // }
    
  }, [value])
  return (
    <div style={{
        display: 'flex', width: 'fit-content', height: '16px', padding: '8px', 
        background: color, borderRadius: '100px', overflow: 'hidden', whiteSpace: 'nowrap', marginRight: '8px'
      }}
    >
      <p style={{ fontSize: '8px', fontWeight: 400,fontStyle: 'normal', fontFamily: 'Poppins', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', color: textColor }}>{displayValue}</p>
    </div>
  )
}

export default AutomationLoanTypeTag