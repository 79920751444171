// Fetch Favourite Bank Providers
export const FETCH_FAVOURITE_BANK_PROVIDERS_REQUEST = "FETCH_FAVOURITE_BANK_PROVIDERS_REQUEST";
export const FETCH_FAVOURITE_BANK_PROVIDERS_SUCCESS = "FETCH_FAVOURITE_BANK_PROVIDERS_SUCCESS";
export const FETCH_FAVOURITE_BANK_PROVIDERS_FAILURE = "FETCH_FAVOURITE_BANK_PROVIDERS_FAILURE";

// Fetch Bank Providers
export const FETCH_BANK_PROVIDERS_REQUEST = "FETCH_BANK_PROVIDERS_REQUEST";
export const FETCH_BANK_PROVIDERS_SUCCESS = "FETCH_BANK_PROVIDERS_SUCCESS";
export const FETCH_BANK_PROVIDERS_FAILURE = "FETCH_BANK_PROVIDERS_FAILURE";

// Fetch Next Bank Providers
export const FETCH_NEXT_BANK_PROVIDERS_REQUEST = "FETCH_NEXT_BANK_PROVIDERS_REQUEST";
export const FETCH_NEXT_BANK_PROVIDERS_SUCCESS = "FETCH_NEXT_BANK_PROVIDERS_SUCCESS";
export const FETCH_NEXT_BANK_PROVIDERS_FAILURE = "FETCH_NEXT_BANK_PROVIDERS_FAILURE";

// Fetch Prev Bank Providers
export const FETCH_PREV_BANK_PROVIDERS_REQUEST = "FETCH_PREV_BANK_PROVIDERS_REQUEST";
export const FETCH_PREV_BANK_PROVIDERS_SUCCESS = "FETCH_PREV_BANK_PROVIDERS_SUCCESS";
export const FETCH_PREV_BANK_PROVIDERS_FAILURE = "FETCH_PREV_BANK_PROVIDERS_FAILURE";
