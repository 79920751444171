// Types
import {
	FETCH_HELP_ARTICLES_DATA_REQUEST,
	FETCH_HELP_ARTICLES_DATA_SUCCESS,
	FETCH_HELP_ARTICLES_DATA_FAILURE,
	FILTER_HELP_ARTICLES_DATA,
} from "./types";

// Initial State
const initialState = {
	helpArticlesData: {
		loading: true,
		data: [],
		error: "",
	},
	generalData: {
		data: [],
	},
	investorData: {
		data: [],
	},
	borrowerData: {
		data: [],
	},
};

// Reducer
const reducer = (state = initialState, action) => {
	switch (action.type) {
		// Fetch Help Articles Data
		case FETCH_HELP_ARTICLES_DATA_REQUEST:
			return {
				...state,
				helpArticlesData: { ...state.helpArticlesData, loading: true },
			};
		case FETCH_HELP_ARTICLES_DATA_SUCCESS:
			return {
				...state,
				helpArticlesData: { ...state.helpArticlesData, loading: false, data: action.payload },
				generalData: {
					...state.generalData,
					data: action.payload.articles.filter(item => item.section_id === 360001426052),
				},
				investorData: {
					...state.investorData,
					data: action.payload.articles.filter(item => item.section_id === 360001426072),
				},
				borrowerData: {
					...state.borrowerData,
					data: action.payload.articles.filter(item => item.section_id === 360001441351),
				},
			};
		case FETCH_HELP_ARTICLES_DATA_FAILURE:
			return {
				...state,
				helpArticlesData: { ...state.helpArticlesData, loading: false, error: action.payload },
			};

		// Filter Help Articles Data
		case FILTER_HELP_ARTICLES_DATA:
			return {
				...state,
				generalData: {
					...state.generalData,
					data: state.helpArticlesData.data.articles.filter(
						item =>
							item.section_id === 360001426052 &&
							item.name.toLowerCase().includes(action.payload.toLowerCase())
					),
				},
				investorData: {
					...state.investorData,
					data: state.helpArticlesData.data.articles.filter(
						item =>
							item.section_id === 360001426072 &&
							item.name.toLowerCase().includes(action.payload.toLowerCase())
					),
				},
				borrowerData: {
					...state.borrowerData,
					data: state.helpArticlesData.data.articles.filter(
						item =>
							item.section_id === 360001441351 &&
							item.name.toLowerCase().includes(action.payload.toLowerCase())
					),
				},
			};

		default:
			return {
				...state,
			};
	}
};

export default reducer;
