import React from "react";
import PropTypes from "prop-types";

// Import Bootstrap Components
import { Modal } from "react-bootstrap";
import ResponseIllustration from '../../common/responseIllustration/responseIllustration'
// CSS
import "./LNModal.css";

function LNModal({ children, dialogClassName = "", show, onHide, modalHeaderText, backdrop = true, error, errorMessage }) {
	return (
		<Modal dialogClassName={"general-modal " + dialogClassName} show={show} onHide={onHide} backdrop={backdrop}>
			<Modal.Header>
				<div>{modalHeaderText}</div>
				<button style={{color:"#000000"}} onClick={onHide} className="btn">
					Close
				</button>
			</Modal.Header>
			{error &&
			<>
			<div style={{
				justifyContent: "center",
				marginTop: 30,
				// alignSelf:"center"
				}}>
					<p style={{fontSize:28,fontWeight:"bold",color:"#454545",marginLeft:50}}>Something went wrong!</p>

					<p style={{fontSize:14,color:"#404040",fontWeight:"lighter",marginLeft:50}}>{errorMessage}</p>
			</div>			
			<div style={{alignItems:"center",marginTop:30}}>
				<div style={{
						display:"flex",
						justifyContent: "center",
						marginTop: 30,
				}}>
					<ResponseIllustration type="error"/>
				</div>
			</div>
			</>
			}
			<Modal.Body>{children}</Modal.Body>
			{error&&
			<Modal.Footer>
				<button
					onClick={onHide}
					className="btn btn-black">
					Done
				</button>
			</Modal.Footer>
			}
		</Modal>
	);
}

LNModal.propTypes = {
	show: PropTypes.bool.isRequired,
	onHide: PropTypes.func.isRequired,
	modalHeaderText: PropTypes.string,
};

export default LNModal;
