import loanService from "../../services/loanService";
import userService from "../../services/userService";
import store from "../store";
import { handleReactGAEvents } from "../../utils/handleReactGAEvents";

// Types
import {
	FETCH_LOAN_REQUESTS_REQUEST,
	FETCH_LOAN_REQUESTS_SUCCESS,
	FETCH_LOAN_REQUESTS_FAILURE,
	FETCH_MORE_LOAN_REQUESTS_REQUEST,
	FETCH_MORE_LOAN_REQUESTS_SUCCESS,
	FETCH_MORE_LOAN_REQUESTS_FAILURE,
	FETCH_FILTERED_LOAN_REQUESTS_REQUEST,
	FETCH_FILTERED_LOAN_REQUESTS_SUCCESS,
	FETCH_FILTERED_LOAN_REQUESTS_FAILURE,
	FETCH_FAV_LOAN_REQUESTS_REQUEST,
	FETCH_FAV_LOAN_REQUESTS_SUCCESS,
	FETCH_FAV_LOAN_REQUESTS_FAILURE,
	FETCH_MORE_FAV_LOAN_REQUESTS_REQUEST,
	FETCH_MORE_FAV_LOAN_REQUESTS_SUCCESS,
	FETCH_MORE_FAV_LOAN_REQUESTS_FAILURE,
	FETCH_CLEAR_FAV_LOAN_REQUESTS_REQUEST,
	FETCH_CLEAR_FAV_LOAN_REQUESTS_SUCCESS,
	FETCH_CLEAR_FAV_LOAN_REQUESTS_FAILURE,
	FETCH_YODLEE_DATA_AGGREGATION_REQUEST,
	FETCH_YODLEE_DATA_AGGREGATION_SUCCESS,
	FETCH_YODLEE_DATA_AGGREGATION_FAILURE,
	FETCH_BORROWER_COMPLETED_LOANS_HISTORY_REQUEST,
	FETCH_BORROWER_COMPLETED_LOANS_HISTORY_SUCCESS,
	FETCH_BORROWER_COMPLETED_LOANS_HISTORY_FAILURE,
	FETCH_FAVORITE_LOAN_REQUEST,
	FETCH_FAVORITE_LOAN_SUCCESS,
	FETCH_FAVORITE_LOAN_FAILURE,
	FETCH_UNFAVORITE_LOAN_REQUEST,
	FETCH_UNFAVORITE_LOAN_SUCCESS,
	FETCH_UNFAVORITE_LOAN_FAILURE,
	FETCH_DISMISS_LOAN_REQUEST,
	FETCH_DISMISS_LOAN_SUCCESS,
	FETCH_DISMISS_LOAN_FAILURE,
	FETCH_OFFER_LOAN_REQUEST,
	FETCH_OFFER_LOAN_SUCCESS,
	FETCH_OFFER_LOAN_FAILURE,
	UPDATE_OFFER_LOANS,
	FETCH_LENME_PREDICTION_REQUEST,
	FETCH_LENME_PREDICTION_SUCCESS,
	FETCH_LENME_PREDICTION_FAILURE,
	SEND_LENME_PREDICTION_RECEIPT_REQUEST,
	SEND_LENME_PREDICTION_RECEIPT_SUCCESS,
	SEND_LENME_PREDICTION_RECEIPT_FAILURE,
} from "./types";


// Fetch Loan Requests
export const fetchLoanRequestsRequest = () => {
	return {
		type: FETCH_LOAN_REQUESTS_REQUEST,
	};
};

export const fetchLoanRequestsSuccess = (data) => {
	return {
		type: FETCH_LOAN_REQUESTS_SUCCESS,
		payload: data,
	};
};

export const fetchLoanRequestsFailure = (error) => {
	return {
		type: FETCH_LOAN_REQUESTS_FAILURE,
		payload: error,
	};
};

export const fetchLoanRequests = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchLoanRequestsRequest());

			const { data } = await loanService.getLoanRequests();
			console.log('loanService_getLoanRequests_data ', data)
			dispatch(fetchLoanRequestsSuccess(data));
		} catch (ex) {
			dispatch(fetchLoanRequestsFailure(ex));
			console.log(ex);
		}
	};
};

// Fetch More Loan Requests
export const fetchMoreLoanRequestsRequest = () => {
	return {
		type: FETCH_MORE_LOAN_REQUESTS_REQUEST,
	};
};

export const fetchMoreLoanRequestsSuccess = (data) => {
	return {
		type: FETCH_MORE_LOAN_REQUESTS_SUCCESS,
		payload: data,
	};
};

export const fetchMoreLoanRequestsFailure = (error) => {
	return {
		type: FETCH_MORE_LOAN_REQUESTS_FAILURE,
		payload: error,
	};
};

export const fetchMoreLoanRequests = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchMoreLoanRequestsRequest());

			const next = store.getState().dashboard.loanRequests.data.next;
			const { data } = await loanService.getMoreLoanRequests(next);
			dispatch(fetchMoreLoanRequestsSuccess(data));
		} catch (ex) {
			dispatch(fetchMoreLoanRequestsFailure(ex));
			console.log(ex);
		}
	};
};

// Fetch Filtered Loan Requests
export const fetchFilteredLoanRequestsRequest = () => {
	return {
		type: FETCH_FILTERED_LOAN_REQUESTS_REQUEST,
	};
};

export const fetchFilteredLoanRequestsSuccess = (data) => {
	return {
		type: FETCH_FILTERED_LOAN_REQUESTS_SUCCESS,
		payload: data,
	};
};

export const fetchFilteredLoanRequestsFailure = (error) => {
	return {
		type: FETCH_FILTERED_LOAN_REQUESTS_FAILURE,
		payload: error,
	};
};

export const fetchFilteredLoanRequests = (query) => {
	return async function (dispatch) {
		try {
			dispatch(fetchFilteredLoanRequestsRequest());

			const { data } = await loanService.fliterRequests(query);
			dispatch(fetchFilteredLoanRequestsSuccess(data));
		} catch (ex) {
			dispatch(fetchFilteredLoanRequestsFailure(ex));
			console.log(ex);
		}
	};
};

// Fetch Fav Loan Requests
export const fetchFavLoanRequestsRequest = () => {
	return {
		type: FETCH_FAV_LOAN_REQUESTS_REQUEST,
	};
};

export const fetchFavLoanRequestsSuccess = (data) => {
	return {
		type: FETCH_FAV_LOAN_REQUESTS_SUCCESS,
		payload: data,
	};
};

export const fetchFavLoanRequestsFailure = (error) => {
	return {
		type: FETCH_FAV_LOAN_REQUESTS_FAILURE,
		payload: error,
	};
};

export const fetchFavLoanRequests = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchFavLoanRequestsRequest());

			const { data } = await loanService.getFavLoanRequest();
			console.log('loanService_getFavLoanRequest_data ', data)
			dispatch(fetchFavLoanRequestsSuccess(data));
		} catch (ex) {
			dispatch(fetchFavLoanRequestsFailure(ex));
			console.log(ex);
		}
	};
};

// Fetch More Fav Loan Requests
export const fetchMoreFavLoanRequestsRequest = () => {
	return {
		type: FETCH_MORE_FAV_LOAN_REQUESTS_REQUEST,
	};
};

export const fetchMoreFavLoanRequestsSuccess = (data) => {
	return {
		type: FETCH_MORE_FAV_LOAN_REQUESTS_SUCCESS,
		payload: data,
	};
};

export const fetchMoreFavLoanRequestsFailure = (error) => {
	return {
		type: FETCH_MORE_FAV_LOAN_REQUESTS_FAILURE,
		payload: error,
	};
};

export const fetchMoreFavLoanRequests = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchMoreFavLoanRequestsRequest());

			const next = store.getState().dashboard.favLoanRequests.data.next;
			const { data } = await loanService.getMoreFavLoanRequest(next);
			dispatch(fetchMoreFavLoanRequestsSuccess(data));
		} catch (ex) {
			dispatch(fetchMoreFavLoanRequestsFailure(ex));
			console.log(ex);
		}
	};
};

// Fetch Clear Fav Loan Requests
export const fetchClearFavLoanRequestsRequest = () => {
	return {
		type: FETCH_CLEAR_FAV_LOAN_REQUESTS_REQUEST,
	};
};

export const fetchClearFavLoanRequestsSuccess = () => {
	return {
		type: FETCH_CLEAR_FAV_LOAN_REQUESTS_SUCCESS,
	};
};

export const fetchClearFavLoanRequestsFailure = (error) => {
	return {
		type: FETCH_CLEAR_FAV_LOAN_REQUESTS_FAILURE,
		payload: error,
	};
};

export const fetchClearFavLoanRequests = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchClearFavLoanRequestsRequest());

			await loanService.clearFavLoanRequests();
			dispatch(fetchFavLoanRequests());
			dispatch(fetchClearFavLoanRequestsSuccess());
		} catch (ex) {
			dispatch(fetchClearFavLoanRequestsFailure(ex));
			console.log(ex);
		}
	};
};

// Fetch Yodlee Data Aggregation Requests
export const fetchYodleeDataAggregationRequest = () => {
	return {
		type: FETCH_YODLEE_DATA_AGGREGATION_REQUEST,
	};
};

export const fetchYodleeDataAggregationSuccess = (data) => {
	return {
		type: FETCH_YODLEE_DATA_AGGREGATION_SUCCESS,
		payload: data,
	};
};

export const fetchYodleeDataAggregationFailure = (error) => {
	return {
		type: FETCH_YODLEE_DATA_AGGREGATION_FAILURE,
		payload: error,
	};
};

export const fetchYodleeDataAggregation = (borrowerId) => {
	return async function (dispatch) {
		try {
			dispatch(fetchYodleeDataAggregationRequest());

			const { data } = await userService.getYodleeDataAggregation(borrowerId);
			dispatch(fetchYodleeDataAggregationSuccess(data));
		} catch (ex) {
			dispatch(fetchYodleeDataAggregationFailure(ex));
			console.log(ex);
		}
	};
};

// Fetch Borrower Completed Loans History
export const fetchBorrowerCompletedLoansHistoryRequest = () => {
	return {
		type: FETCH_BORROWER_COMPLETED_LOANS_HISTORY_REQUEST,
	};
};

export const fetchBorrowerCompletedLoansHistorySuccess = (data) => {
	return {
		type: FETCH_BORROWER_COMPLETED_LOANS_HISTORY_SUCCESS,
		payload: data,
	};
};

export const fetchBorrowerCompletedLoansHistoryFailure = (error) => {
	return {
		type: FETCH_BORROWER_COMPLETED_LOANS_HISTORY_FAILURE,
		payload: error,
	};
};

export const fetchBorrowerCompletedLoansHistory = (borrowerId) => {
	return async function (dispatch) {
		try {
			dispatch(fetchBorrowerCompletedLoansHistoryRequest());

			const { data } = await loanService.getBorrowerCompletedLoans(borrowerId);
			dispatch(fetchBorrowerCompletedLoansHistorySuccess(data));
		} catch (ex) {
			dispatch(fetchBorrowerCompletedLoansHistoryFailure(ex));
			console.log(ex);
		}
	};
};

// Fetch Favorite Loan Request
export const fetchFavoriteLoanRequest = () => {
	return {
		type: FETCH_FAVORITE_LOAN_REQUEST,
	};
};

export const fetchFavoriteLoanSuccess = (requestId, favLoanRequestId) => {
	return {
		type: FETCH_FAVORITE_LOAN_SUCCESS,
		requestId,
		favLoanRequestId,
	};
};

export const fetchFavoriteLoanFailure = (error) => {
	return {
		type: FETCH_FAVORITE_LOAN_FAILURE,
		payload: error,
	};
};

export const fetchFavoriteLoan = (requestId) => {
	return async function (dispatch) {
		try {
			dispatch(fetchFavoriteLoanRequest());

			const { data } = await loanService.favLoanRequest(requestId);
			console.log('loanService_favLoanRequest_data ', data)
			dispatch(fetchFavoriteLoanSuccess(requestId, data.id));
		} catch (ex) {
			dispatch(fetchFavoriteLoanFailure(ex));
			console.error("loanService_favLoanRequest_err " , ex);
		}
	};
};

// Fetch Unfavorite Loan Request
export const fetchUnfavoriteLoanRequest = () => {
	return {
		type: FETCH_UNFAVORITE_LOAN_REQUEST,
	};
};

export const fetchUnfavoriteLoanSuccess = (requestId) => {
	return {
		type: FETCH_UNFAVORITE_LOAN_SUCCESS,
		requestId,
	};
};

export const fetchUnfavoriteLoanFailure = (error) => {
	return {
		type: FETCH_UNFAVORITE_LOAN_FAILURE,
		payload: error,
	};
};

export const fetchUnfavoriteLoan = (requestId, favRequestId) => {
	return async function (dispatch) {
		try {
			dispatch(fetchUnfavoriteLoanRequest());

			await loanService.unfavLoanRequest(favRequestId);
			dispatch(fetchUnfavoriteLoanSuccess(requestId));
		} catch (ex) {
			dispatch(fetchUnfavoriteLoanFailure(ex));
			console.log(ex);
		}
	};
};

// Fetch Dismiss Loan Request
export const fetchDismissLoanRequest = (requestId) => {
	return {
		type: FETCH_DISMISS_LOAN_REQUEST,
		requestId,
	};
};

export const fetchDismissLoanSuccess = () => {
	return {
		type: FETCH_DISMISS_LOAN_SUCCESS,
	};
};

export const fetchDismissLoanFailure = (error) => {
	return {
		type: FETCH_DISMISS_LOAN_FAILURE,
		payload: error,
	};
};

export const fetchDismissLoan = (requestId) => {
	return async function (dispatch) {
		try {
			dispatch(fetchDismissLoanRequest(requestId));

			await loanService.dismissLoanRequest(requestId);
			dispatch(fetchDismissLoanSuccess());

			// Send GA event
			handleReactGAEvents("cancel_offer");
		} catch (ex) {
			dispatch(fetchDismissLoanFailure(ex));
			console.log(ex);
		}
	};
};

// Fetch Offer Loan Request
export const fetchOfferLoanRequest = () => {
	return {
		type: FETCH_OFFER_LOAN_REQUEST,
	};
};

export const fetchOfferLoanSuccess = (requestId) => {
	return {
		type: FETCH_OFFER_LOAN_SUCCESS,
		requestId,
	};
};

export const fetchOfferLoanFailure = (error) => {
	return {
		type: FETCH_OFFER_LOAN_FAILURE,
		payload: error,
	};
};

export const fetchOfferLoan = (requestId, offeredInterest) => {
	return async function (dispatch) {
		try {
			dispatch(fetchOfferLoanRequest());
			console.log('fetchOfferLoan_requestId ', requestId);
			const { data } = await loanService.offerLoanRequest(requestId, offeredInterest / 100);
			console.log('fetchOfferLoan_data ', data)
			dispatch(fetchOfferLoanSuccess(requestId));

			// Send GA event
			handleReactGAEvents("submit_offer");
		} catch (err) {
			const errMessage = err.response?.data?.message ?? err.response?.data?.detail;
			dispatch(fetchOfferLoanFailure(errMessage));
			console.log('fetchOfferLoan_err ', err);
		}
	};
};

// Update Offer Loans
export const updateOfferLoans = (requestId) => {
	return {
		type: UPDATE_OFFER_LOANS,
		requestId: requestId,
	};
};

// Fetch Lenme Prediction
export const fetchLenmePredictionRequest = () => {
	return {
		type: FETCH_LENME_PREDICTION_REQUEST,
	};
};

export const fetchLenmePredictionSuccess = (data) => {
	return {
		type: FETCH_LENME_PREDICTION_SUCCESS,
		payload: data,
	};
};

export const fetchLenmePredictionFailure = (error) => {
	return {
		type: FETCH_LENME_PREDICTION_FAILURE,
		payload: error,
	};
};

export const fetchLenmePrediction = (loan_request) => {
	return async function (dispatch) {
		try {
			dispatch(fetchLenmePredictionRequest());

			const { data } = await loanService.getLenmePrediction(loan_request);
			dispatch(fetchLenmePredictionSuccess(data));
		} catch (ex) {
			dispatch(fetchLenmePredictionFailure(ex));
			console.log(ex);
		}
	};
};

// Send Lenme Prediction Receipt
export const sendLenmePredictionReceiptRequest = () => {
	return {
		type: SEND_LENME_PREDICTION_RECEIPT_REQUEST,
	};
};

export const sendLenmePredictionReceiptSuccess = (data) => {
	return {
		type: SEND_LENME_PREDICTION_RECEIPT_SUCCESS,
		payload: data,
	};
};

export const sendLenmePredictionReceiptFailure = (error) => {
	return {
		type: SEND_LENME_PREDICTION_RECEIPT_FAILURE,
		payload: error,
	};
};

let tryRequestCounter = 5;
export const sendLenmePredictionReceipt = (receipt, device, productId, loan_request) => {
	return async function (dispatch) {
		try {
			dispatch(sendLenmePredictionReceiptRequest());

			const { data } = await loanService.sendLenmePredictionReceipt(receipt, device, productId, loan_request);
			dispatch(sendLenmePredictionReceiptSuccess(data));
		} catch (ex) {
			if (tryRequestCounter > 0) {
				tryRequestCounter -= 1;
				dispatch(sendLenmePredictionReceipt(receipt, device, productId, loan_request));
				return;
			}

			if (tryRequestCounter === 0) {
				tryRequestCounter = 5;
				dispatch(sendLenmePredictionReceiptFailure(ex));
				console.log(ex);
				return;
			}
		}
	};
};


