import React, { Component } from "react";
// import PropTypes from "prop-types";

// Import Services

// Import Utils

// Import Material Components

// Import Components

// Import Images

// Import CSS
import "./notFoundPage.css";

class NotFoundPage extends Component {
    state = {
        loading: true
    };

    // Handle Loading
    handleLoading() {
        const loading = false;
        this.setState({ loading });
    }

    // When Mount
    componentDidMount() {
        // End loading
        this.handleLoading();
    }

    // Helper Methods
    handleMethod = () => {};

    render() {
        const { loading } = this.state;

        // Check if loading
        if (loading) return <div>Loading...</div>;

        // Render
        return <div>404 Not Found</div>;
    }
}

// Prop Types
NotFoundPage.propTypes = {};

export default NotFoundPage;
