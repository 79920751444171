import React from "react";
import PropTypes from "prop-types";

// Components
import PopupHeader from "../common/popupHeader/popupHeader";

// CSS
import "./infoCardDetails.css";

function InfoCardDetails({ title, legend, body, visible_function }) {
	// Render
	return (
		<div>
			<PopupHeader title={title} handleBackClick={visible_function} />

			<div className="modal-left-space-container">
				<div className="small mt-5">{body}</div>

				{legend && (
					<div className="text-center mt-5">
						<img src={legend} alt="Payment History Legend" width="100%" />
					</div>
				)}
			</div>

			<div className="modal-buttons-wrapper horizontal">
				<button onClick={() => visible_function()} className="btn btn-black btn-lg">
					Got it
				</button>
			</div>
		</div>
	);
}

// Prop Types
InfoCardDetails.propTypes = {
	title: PropTypes.string.isRequired,
	body: PropTypes.string.isRequired,
	legend: PropTypes.string,
	visible_function: PropTypes.func.isRequired,
};

export default InfoCardDetails;
