import React, { useState } from "react";
import PropTypes from "prop-types";

// Import CSS
import "./LNInput.css";

const LNInput = ({
	label,
	type = "text",
	name,
	value,
	onChange,
	onClick,
	placeholder,
	autoComplete = "on",
	autoFocus = false,
	inputGroupType,
	inputRef,
	readOnly,
	text,
	error,
	removablePlaceholder = false,
}) => {
	const [_placeholder, setPlaceholder] = useState(placeholder);

	// Helper Methods
	function handleInputGroupClasses(value) {
		switch (value) {
			case "small":
				return "input-group input-group-sm";
			case "large":
				return "input-group input-group-lg";
			default:
				return "input-group";
		}
	}

	function handleInputClasses(error) {
		if (error) return "form-control is-invalid";
		return "form-control";
	}

	function handleRemovePlaceholder() {
		if (removablePlaceholder) {
			setPlaceholder("");
		}
	}

	function handleAddPlaceholder() {
		if (removablePlaceholder) {
			setPlaceholder(placeholder);
		}
	}

	return (
		<div className="form-group">
			{label && <label htmlFor={name}>{label}</label>}
			<div className={handleInputGroupClasses(inputGroupType)}>
				<input
					type={type}
					name={name}
					value={value}
					onChange={onChange}
					onClick={onClick}
					className={handleInputClasses(error)}
					id={name}
					placeholder={_placeholder}
					autoComplete={autoComplete}
					autoFocus={autoFocus}
					ref={inputRef}
					readOnly={readOnly}
					onFocus={handleRemovePlaceholder}
					onBlur={handleAddPlaceholder}
				/>
				{error && <div className="invalid-feedback">{error}</div>}
			</div>
			{text && <small className="form-text text-muted">{text}</small>}
		</div>
	);
};

// Prop Types
LNInput.propTypes = {
	prop: PropTypes.any,
};

export default LNInput;
