// Fetch Loan Requests
export const FETCH_LOAN_REQUESTS_REQUEST = "FETCH_LOAN_REQUESTS_REQUEST";
export const FETCH_LOAN_REQUESTS_SUCCESS = "FETCH_LOAN_REQUESTS_SUCCESS";
export const FETCH_LOAN_REQUESTS_FAILURE = "FETCH_LOAN_REQUESTS_FAILURE";

// Fetch More Loan Requests
export const FETCH_MORE_LOAN_REQUESTS_REQUEST = "FETCH_MORE_LOAN_REQUESTS_REQUEST";
export const FETCH_MORE_LOAN_REQUESTS_SUCCESS = "FETCH_MORE_LOAN_REQUESTS_SUCCESS";
export const FETCH_MORE_LOAN_REQUESTS_FAILURE = "FETCH_MORE_LOAN_REQUESTS_FAILURE";

// Fetch Filtered Loan Requests
export const FETCH_FILTERED_LOAN_REQUESTS_REQUEST = "FETCH_FILTERED_LOAN_REQUESTS_REQUEST";
export const FETCH_FILTERED_LOAN_REQUESTS_SUCCESS = "FETCH_FILTERED_LOAN_REQUESTS_SUCCESS";
export const FETCH_FILTERED_LOAN_REQUESTS_FAILURE = "FETCH_FILTERED_LOAN_REQUESTS_FAILURE";

// Fetch Fav Loan Requests
export const FETCH_FAV_LOAN_REQUESTS_REQUEST = "FETCH_FAV_LOAN_REQUESTS_REQUEST";
export const FETCH_FAV_LOAN_REQUESTS_SUCCESS = "FETCH_FAV_LOAN_REQUESTS_SUCCESS";
export const FETCH_FAV_LOAN_REQUESTS_FAILURE = "FETCH_FAV_LOAN_REQUESTS_FAILURE";

// Fetch More Fav Loans Requests
export const FETCH_MORE_FAV_LOAN_REQUESTS_REQUEST = "FETCH_MORE_FAV_LOAN_REQUESTS_REQUEST";
export const FETCH_MORE_FAV_LOAN_REQUESTS_SUCCESS = "FETCH_MORE_FAV_LOAN_REQUESTS_SUCCESS";
export const FETCH_MORE_FAV_LOAN_REQUESTS_FAILURE = "FETCH_MORE_FAV_LOAN_REQUESTS_FAILURE";

// Fetch Clear Fav Loans Requests
export const FETCH_CLEAR_FAV_LOAN_REQUESTS_REQUEST = "FETCH_CLEAR_FAV_LOAN_REQUESTS_REQUEST";
export const FETCH_CLEAR_FAV_LOAN_REQUESTS_SUCCESS = "FETCH_CLEAR_FAV_LOAN_REQUESTS_SUCCESS";
export const FETCH_CLEAR_FAV_LOAN_REQUESTS_FAILURE = "FETCH_CLEAR_FAV_LOAN_REQUESTS_FAILURE";

// Fetch Yodlee Data Aggregation Requests
export const FETCH_YODLEE_DATA_AGGREGATION_REQUEST = "FETCH_YODLEE_DATA_AGGREGATION_REQUEST";
export const FETCH_YODLEE_DATA_AGGREGATION_SUCCESS = "FETCH_YODLEE_DATA_AGGREGATION_SUCCESS";
export const FETCH_YODLEE_DATA_AGGREGATION_FAILURE = "FETCH_YODLEE_DATA_AGGREGATION_FAILURE";

// Fetch Borrower Completed Loans History
export const FETCH_BORROWER_COMPLETED_LOANS_HISTORY_REQUEST = "FETCH_BORROWER_COMPLETED_LOANS_HISTORY_REQUEST";
export const FETCH_BORROWER_COMPLETED_LOANS_HISTORY_SUCCESS = "FETCH_BORROWER_COMPLETED_LOANS_HISTORY_SUCCESS";
export const FETCH_BORROWER_COMPLETED_LOANS_HISTORY_FAILURE = "FETCH_BORROWER_COMPLETED_LOANS_HISTORY_FAILURE";

// Fetch Favorite Loan Request
export const FETCH_FAVORITE_LOAN_REQUEST = "FETCH_FAVORITE_LOAN_REQUEST";
export const FETCH_FAVORITE_LOAN_SUCCESS = "FETCH_FAVORITE_LOAN_SUCCESS";
export const FETCH_FAVORITE_LOAN_FAILURE = "FETCH_FAVORITE_LOAN_FAILURE";

// Fetch Unfavorite Loan Request
export const FETCH_UNFAVORITE_LOAN_REQUEST = "FETCH_UNFAVORITE_LOAN_REQUEST";
export const FETCH_UNFAVORITE_LOAN_SUCCESS = "FETCH_UNFAVORITE_LOAN_SUCCESS";
export const FETCH_UNFAVORITE_LOAN_FAILURE = "FETCH_UNFAVORITE_LOAN_FAILURE";

// Fetch Dismiss Loan Request
export const FETCH_DISMISS_LOAN_REQUEST = "FETCH_DISMISS_LOAN_REQUEST";
export const FETCH_DISMISS_LOAN_SUCCESS = "FETCH_DISMISS_LOAN_SUCCESS";
export const FETCH_DISMISS_LOAN_FAILURE = "FETCH_DISMISS_LOAN_FAILURE";

// Fetch Offer Loan Request
export const FETCH_OFFER_LOAN_REQUEST = "FETCH_OFFER_LOAN_REQUEST";
export const FETCH_OFFER_LOAN_SUCCESS = "FETCH_OFFER_LOAN_SUCCESS";
export const FETCH_OFFER_LOAN_FAILURE = "FETCH_OFFER_LOAN_FAILURE";

// Update Offer Loans
export const UPDATE_OFFER_LOANS = "UPDATE_OFFER_LOANS";

// Fetch Lenme Prediction
export const FETCH_LENME_PREDICTION_REQUEST = "FETCH_LENME_PREDICTION_REQUEST";
export const FETCH_LENME_PREDICTION_SUCCESS = "FETCH_LENME_PREDICTION_SUCCESS";
export const FETCH_LENME_PREDICTION_FAILURE = "FETCH_LENME_PREDICTION_FAILURE";

// Send Lenme Prediction Receipt
export const SEND_LENME_PREDICTION_RECEIPT_REQUEST = "SEND_LENME_PREDICTION_RECEIPT_REQUEST";
export const SEND_LENME_PREDICTION_RECEIPT_SUCCESS = "SEND_LENME_PREDICTION_RECEIPT_SUCCESS";
export const SEND_LENME_PREDICTION_RECEIPT_FAILURE = "SEND_LENME_PREDICTION_RECEIPT_FAILURE";
