import React, { Component } from "react";
import { connect } from "react-redux";
import {
	fetchFavouriteBankProviders,
	fetchBankProviders,
	fetchNextBankProviders,
	fetchPrevBankProviders,
} from "../../redux";
import queryString from "query-string";
import PropTypes from "prop-types";
import history from "../../history";

// Import Services
import http from "../../services/httpService";
import { checkIfFiservAccountAdded, updateFiservAccount303Flow } from "../../services/bankService";

// Utils
import { handleReactGAEvents } from "../../utils/handleReactGAEvents";

// Import Components
import LNModal from "../common/LNModal/LNModal";
import ResponseIllustration from "../common/responseIllustration/responseIllustration";

// Import Images
import SearchIcon from "../../img/icons/muted/search.svg";
import LeftArrowIcon from "../../img/icons/blue/left-arrow.svg";
import Spinner from "../../img/spinners/offer_loading.gif";
import { DotLoaderOverlay } from 'react-spinner-overlay'


// CSS
import "./bankProviders.css";

class BankProviders extends Component {
	state = {
		loading: true,
		spinnerLoading: false,
		modal: {
			linkBank: {
				loading: true,
				visible: false,
				message: "",
				error: "",
			},
		},
		bankLoginWidget: {
			loading: true,
			visible: false,
			providerId: "",
			providerName: "",
			token: "",
			fiLoginAcctId: "",
			url: "",
		},
	};

	// Get iframe postMessage
	getIframePostMessage() {
		const receiveMessage = async (event) => {
			setTimeout(() => {

			
				// Something from an unknown domain, let's ignore it
				if (event.origin !== process.env.REACT_APP_API_URL2) return;

				// Log
				console.log('IfpostMessage ', event);

				// Check the returned queryString
				this.checkQueryString(event.data);

				// Hide bank login widget
				this.hideBankLoginWidget();

				
			}, 500)
		};

		// Add new listener
		window.addEventListener("message", receiveMessage);
	}

	// Check Query String
	async checkQueryString(data) {
		const modal = { ...this.state.modal };
		const query = queryString.parse(data);
		const { providerId, providerName, fiLoginAcctId } = this.state.bankLoginWidget;
		// console.log('checkQueryString_data ', data)
		// console.log('checkQueryString_query ', query)

		switch (query.action) {
			case "Submit":
				try {
					// Show modal
					this.showModal("linkBank");

					// Start modal loading
					modal.linkBank.loading = true;
					this.setState({ modal });

					// Get data
					const { data } = await updateFiservAccount303Flow(fiLoginAcctId, providerId);

					// End modal loading
					modal.linkBank.loading = false;
					this.setState({ modal });

					// Set success message
					modal.linkBank.message = data.detail;
					this.setState({ modal });

					// Send react GA event
					this.handleGoogleAnalyticsEvents("success");
				} catch (ex) {
					if (ex.response && ex.response.data && ex.response.status === 400) {
						console.log(ex.response.data);

						// End modal loading
						modal.linkBank.loading = false;
						this.setState({ modal });

						// Set error message
						modal.linkBank.error = ex.response.data.detail;
						this.setState({ modal });

						// Send react GA event
						this.handleGoogleAnalyticsEvents("failure");
					}
				}
				break;

			// Cancel
			case "cancel":
			case "exit":
				// Back to the transfer page
				this.props.hideProviders();
				history.push("/user/bank-transfer");
				break;

			// Error
			case "error":
				this.verify(providerId, providerName);
				break;

			// InvalidHomeID
			case "InvalidHomeID":
				// Show modal
				this.showModal("linkBank");

				// Set cancel error
				modal.linkBank.loading = false;
				modal.linkBank.error = "Invalid Home ID";
				this.setState({ modal });
				break;

			default:
				break;
		}

		// Added
		if (query.fiLoginAcctId) {
			try {
				// Show modal
				this.showModal("linkBank");

				// Start modal loading
				modal.linkBank.loading = true;
				this.setState({ modal });

				// Get data
				const { data } = await checkIfFiservAccountAdded(query.fiLoginAcctId, providerId);

				// Store the FILoginID
				const bankLoginWidget = { ...this.state.bankLoginWidget };
				bankLoginWidget.fiLoginAcctId = query.fiLoginAcctId;
				this.setState({ bankLoginWidget });

				// End modal loading
				modal.linkBank.loading = false;
				this.setState({ modal });

				// Set success message
				modal.linkBank.message = data.detail;
				this.setState({ modal });

				// Send react GA event
				this.handleGoogleAnalyticsEvents("success");
			} catch (err) {
				if (err.response) {
					if (err.response.status === 400) {
						console.log('err_response_data ', err.response.data);

						// 303
						if (err.response.data && err.response.data.StatusCode === "303") {
							const { REACT_APP_API_VERSION, REACT_APP_API_URL2 } = process.env;
							const { providerName } = this.state.bankLoginWidget;

							// Get new token
							console.log("Fetch new token loading...");

							const { data } = await http.post(`/v${REACT_APP_API_VERSION}/yodlee-customer/token/`, {
								provider_name: providerName,
							});

							console.log("Fetch new token loaded successfully");

							// Get 303 service endpoint load the iframe
							const linkBankService303Endpoint = `${REACT_APP_API_URL2}/financial/fiServResolutionWidgetIform/?login_acct_id=${query.fiLoginAcctId}&sessionToken=${data.token}&return_url=${REACT_APP_API_URL2}/financial/fiserveCallbackWeb&error_url=${REACT_APP_API_URL2}/financial/fiserveErrorCallbackWeb`;

							// Add the url of the iframe
							const bankLoginWidget = { ...this.state.bankLoginWidget };
							bankLoginWidget.url = linkBankService303Endpoint;
							this.setState({ bankLoginWidget });

							// Hide linking bank modal
							this.hideModal("linkBank");

							setTimeout(() => {

								// Start bank login widget loading
								this.startBankLoginWidgetLoading();
	
								// Show bank login widget
								this.showBankLoginWidget();
							}, 500)

						} else {
							// End modal loading
							modal.linkBank.loading = false;
							this.setState({ modal });

							// Set error message
							modal.linkBank.error = err.response.data.detail;
							this.setState({ modal });

							// Send react GA event
							this.handleGoogleAnalyticsEvents("failure");
						}
					} else {
						console.log("Fiserv exception:\n", err.response);
					}
				}
				else {
					console.log('no_err_response')
				}
			}
		}
	}

	// Populate Favourite Providers
	async populateFavouriteProviders() {
		await this.props.fetchFavouriteBankProviders();
	}

	// Handle Loading
	handleLoading() {
		this.setState({ loading: false });
	}

	// When Mount
	async componentDidMount() {
		// Get iframe postMessage
		this.getIframePostMessage();

		// Populate favourite providers
		await this.populateFavouriteProviders();

		// Handle loading
		this.handleLoading();
	}

	// Helper Methods
	searchProviders = (provider) => {
		// Validate
		if (!provider) provider = "";

		this.props.fetchBankProviders(provider);
	};

	verify = async (providerId, providerName) => {
		const { modal } = this.state;
		this.setState({spinnerLoading: true})
		try {
			console.log("Fetch token loading...");

			const { data } = await http.post(`/v${process.env.REACT_APP_API_VERSION}/yodlee-customer/token/`, {
				provider_name: providerName,
			});

			console.log("Fetch token loaded successfully");

			// Get token and load the iframe
			const datahub_app_id = "APP1";

			// Use providerId = 505003 for 303 response
			const linkBankServiceEndpoint = `${process.env.REACT_APP_API_URL2}/financial/yodlee-html/?providerName=${providerName}&token=${data.token}&providerId=${providerId}&datahub_app_id=${datahub_app_id}&return_url=${process.env.REACT_APP_API_URL2}/financial/fiserveCallbackWeb&error_url=${process.env.REACT_APP_API_URL2}/financial/fiserveErrorCallbackWeb/`;
			// console.log('linkBankServiceEndpoint ', linkBankServiceEndpoint)
			// Add the url of the iframe
			const bankLoginWidget = { ...this.state.bankLoginWidget };
			// console.log('bankLoginWidget ', bankLoginWidget)
			bankLoginWidget.url = linkBankServiceEndpoint;
			bankLoginWidget.providerId = providerId;
			bankLoginWidget.providerName = providerName;
			bankLoginWidget.token = data.token;
			// console.log('bankLoginWidget_2 ', bankLoginWidget)
			this.setState({ bankLoginWidget: bankLoginWidget });

			setTimeout(() => {
				// console.log('timerr 2000')
				console.log('startBankLoginWidgetLoading')
				// Start bank login widget loading
				this.startBankLoginWidgetLoading();
	
				// Show bank login widget
				this.showBankLoginWidget();
				this.setState({spinnerLoading: false})
			}, 100)
		} catch (exc) {
			console.log('verify_exc ', exc);

			if (exc.response) {
				if (exc.response.status === 400 || exc.response.status === 404) {
					console.log('verify_catch_exc_res_data ', exc.response.data);
					const errorMessage = exc.response.data?.errorMessage || exc.response.data?.Error;
					console.log('errorMessage ', errorMessage);
					if (errorMessage) {
						modal.linkBank.error = errorMessage;
						// End modal loading
						modal.linkBank.loading = false;
						this.setState({ modal, spinnerLoading: false });
						this.showModal('linkBank');
					}
					else {
						console.error('yodlee_token_no_errorMessage ', errorMessage);
						modal.linkBank.loading = false;
						this.setState({ modal, spinnerLoading: false });
					}
				}
				else if (exc.response.status === 401) {
					console.log('verify_catch_exc_response ', exc.response);
					modal.linkBank.error = exc.response.data?.Error;
					// End modal loading
					modal.linkBank.loading = false;
					this.setState({ modal, spinnerLoading: false });
					this.showModal('linkBank');
				}
			}
		}
	};

	showModal = (modalName) => {
		const modal = { ...this.state.modal };
		modal[modalName].visible = true;
		this.setState({ modal });
	};

	hideModal = (modalName) => {
		const modal = { ...this.state.modal };
		modal[modalName].visible = false;
		this.setState({ modal: modal });
	};

	showBankLoginWidget = () => {
		// console.log('showBankLoginWidget')
		const bankLoginWidget = { ...this.state.bankLoginWidget };
		bankLoginWidget.visible = true;
		this.setState({ bankLoginWidget: bankLoginWidget });
	};

	hideBankLoginWidget = () => {
		const bankLoginWidget = { ...this.state.bankLoginWidget };
		bankLoginWidget.visible = false;
		this.setState({ bankLoginWidget });
	};

	startBankLoginWidgetLoading = () => {
		const bankLoginWidget = { ...this.state.bankLoginWidget };
		bankLoginWidget.loading = true;
		this.setState({ bankLoginWidget: bankLoginWidget });
	};

	stopBankLoginWidgetLoading = () => {
		const bankLoginWidget = { ...this.state.bankLoginWidget };
		bankLoginWidget.loading = false;
		this.setState({ bankLoginWidget });
	};

	handleDoneButtonSuccess = () => {
		// Hide modal
		this.hideModal("linkBank");

		// Route to the dashboard if in signup process
		if (this.props.renderdFromSignup) {
			history.push("/user/dashboard");
		}
	};

	handleDoneButtonFailure = () => {
		// Hide modal
		this.hideModal("linkBank");

		// Route to the dashboard if in signup process
		if (this.props.renderdFromSignup) {
			history.push("/user/dashboard");
		}
	};

	handleGoogleAnalyticsEvents = (status) => {
		// Success
		if (status === "success") {
			handleReactGAEvents("add_bank_account_verified");
		}

		// Failure
		if (status === "failure") {
			handleReactGAEvents("add_bank_account_unverified");
		}
	};

	render() {
		const { loading, modal, bankLoginWidget } = this.state;
		const { providersData } = this.props;
		const { providers } = providersData;

		// Check if loading
		// console.log('render_bankLoginWidget', bankLoginWidget)
		// console.log('render_loading', loading)
		// console.log('modal_obj ', modal)
		if (loading) return null;

		// Render
		return (
			<div>
				{/* header */}
				<div className="form-header mb-4">
					<div className="back-button pointer d-flex flex-row" onClick={this.props.hideProviders}>
						<img src={LeftArrowIcon} alt="<" width="9" />
						<span className="ml-2">Back</span>
					</div>
					<div className="main-form-label my-3">Select your bank</div>
				</div>

				<div className="white-search-bar">
					<img src={SearchIcon} alt="Search" />
					<input
						type="text"
						placeholder="Search"
						onChange={(e) => {
							this.searchProviders(e.target.value);
						}}
					/>
				</div>

				<div className="link-bank-cards-container row no-gutters">
					{providers.results.map((provider) => (
						<div
							key={provider.yodlee_id}
							onClick={() => this.verify(provider.yodlee_id, provider.provider_name)}
							className="col-6 col-sm-4 col-lg-4">
							<div className="link-bank-card p-3 py-md-5 bg-white rounded-lg m-3">
								<img src={provider.logo} alt={provider.name} />
								<div className="d-none d-md-block mt-3 font-weight-bold text-center font-size-14">
									{provider.name}
								</div>
							</div>
						</div>
					))}
				</div>

				<div className="position-relative">
					{providers.previous && (
						<div
							className="pointer previous-bank-results"
							onClick={() => {
								this.props.fetchPrevBankProviders();
							}}>
							Previous
						</div>
					)}

					{providers.next && (
						<div
							className="pointer next-bank-results"
							onClick={() => {
								this.props.fetchNextBankProviders();
							}}>
							Next
						</div>
					)}
				</div>
				{this.state.spinnerLoading && (
					<DotLoaderOverlay
						loading={true} 
						// overlayColor="rgba(255,255,255,0.7)"
						color="rgba(0,0,0,0.7)"
					/>
				)}

				{/* Bank Login Widget */}
				{bankLoginWidget.visible && (
					<div className="bank-login-widget">
						<div>
							<button onClick={this.hideBankLoginWidget} className="close">
								<span>&times;</span>
							</button>
						</div>
						<div className="frame-container mt-3">
							<div className="frame-wrapper">
								{bankLoginWidget.loading && ( 
									<div className="loading-spinner d-flex align-items-center">
										<img src={Spinner} alt="Loading..." width="40" />
										<span>Loading...</span>
									</div>
								)}

								<iframe
									src={bankLoginWidget.url}
									onLoad={this.stopBankLoginWidgetLoading}
									style={{border: 'none'}}
									title="bankLoginFrame"
									name="bankLoginFrame"
								/>
							</div>
						</div>
					</div>
				)}

				{/* Link Bank Modal */}
				<LNModal show={modal.linkBank.visible} onHide={() => this.hideModal("linkBank")} backdrop="static">
					{/* Loading */}
					{modal.linkBank.loading && (
						<div className="d-flex justify-content-center">
							<img src={Spinner} alt="Loading..." width="40" />
							<span className="ml-2">You are almost there. Verifying your bank account.</span>
						</div>
					)}

					{/* Success */}
					{!modal.linkBank.loading && !modal.linkBank.error && (
						<>
							<div className="modal-title">Success!</div>
							<div className="modal-text">{modal.linkBank.message}</div>
							<div className="modal-image">
								<ResponseIllustration type="success" />
							</div>
						</>
					)}

					{/* Failure */}
					{!modal.linkBank.loading && modal.linkBank.error && (
						<>
							<div className="modal-title">Error!</div>
							<div className="modal-text">{modal.linkBank.error}</div>
							<div className="modal-image">
								<ResponseIllustration type="error" />
							</div>
						</>
					)}

					{/* Footer */}
					<div className="modal-buttons-wrapper horizontal">
						{/* Success Case */}
						{!modal.linkBank.error && (
							<button onClick={this.handleDoneButtonSuccess} className="btn btn-black btn-lg">
								Done
							</button>
						)}

						{/* Failure Case */}
						{modal.linkBank.error && (
							<button onClick={this.handleDoneButtonFailure} className="btn btn-black btn-lg">
								Done
							</button>
						)}
					</div>
				</LNModal>
			</div>
		);
	}
}

// Prop Types
BankProviders.propTypes = {
	hideProviders: PropTypes.func.isRequired,
	renderdFromSignup: PropTypes.bool,
};

function mapStateToProps(state) {
	return {
		providersData: state.bank.providersData,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchFavouriteBankProviders: () => dispatch(fetchFavouriteBankProviders()),
		fetchBankProviders: (query) => dispatch(fetchBankProviders(query)),
		fetchNextBankProviders: () => dispatch(fetchNextBankProviders()),
		fetchPrevBankProviders: () => dispatch(fetchPrevBankProviders()),
	};
}

export default connect(mapStateToProps, mapDispatchToProps)(BankProviders);
