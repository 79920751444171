import { combineReducers } from "redux";

// App Reducers
import userReducer from "./user/reducer";
import feedReducer from "./feed/reducer";
import investmentReducer from "./investment/reducer";
import notificationsReducer from "./notifications/reducer";
import bankReducer from "./bank/reducer";
import supportReducer from "./support/reducer";
import dashboardReducer from "./dashboard/reducer";
import generalReducer from "./general/reducer";

export default combineReducers({
	user: userReducer,
	feed: feedReducer,
	requests: investmentReducer,
	notifications: notificationsReducer,
	bank: bankReducer,
	support: supportReducer,
	dashboard: dashboardReducer,
	general: generalReducer,
});
