import React, { Component } from "react";
import PropTypes from "prop-types";

// Import Services

// Import Utils

// Import Components
import Slider from "react-rangeslider";

// Import Images

// Import CSS
import "./LNRangeSlider.css";

class LNRangeSlider extends Component {
	state = {
		value: 0,
	};

	// Initiate Value
	initiateValue() {
		const value = this.props.value;
		this.setState({ value });
	}

	// When Mount
	componentDidMount() {
		// Initiate Value
		this.initiateValue();
	}

	// Helper Methods
	handleChangeStart = () => {
		//console.log("Change event started");
	};

	handleChange = value => {
		this.setState({ value });
		this.props.change_func(value);
	};

	handleChangeComplete = () => {
		//console.log("Change event completed");
	};

	// Render
	render() {
		const { value } = this.state;
		const { min, max } = this.props;

		// Render
		return (
			<div>
				<Slider
					min={min}
					max={max}
					value={value}
					onChangeStart={this.handleChangeStart}
					onChange={this.handleChange}
					onChangeComplete={this.handleChangeComplete}
					className="mb-1"
				/>
				<div className="slider-range mt-2">
					<div>{min}%</div>
					<div>{max}%</div>
				</div>
			</div>
		);
	}
}

// Prop Types
LNRangeSlider.propTypes = {
	value: PropTypes.number.isRequired,
	change_func: PropTypes.func.isRequired,
};

export default LNRangeSlider;
