import http from "./httpService";

const appVersion = process.env.REACT_APP_API_VERSION;
const apiEndpoint = "/v" + appVersion + "/feed/?type=investing";

// Get Feed Data
export async function getFeed() {
	return await http.get(apiEndpoint);
}

// Get Feed Next
export async function getFeedNext(next) {
	return await http.get(next);
}
