import bankService from "../../services/bankService";
import store from "../store";

// Types
import {
	FETCH_FAVOURITE_BANK_PROVIDERS_REQUEST,
	FETCH_FAVOURITE_BANK_PROVIDERS_SUCCESS,
	FETCH_FAVOURITE_BANK_PROVIDERS_FAILURE,
	FETCH_BANK_PROVIDERS_REQUEST,
	FETCH_BANK_PROVIDERS_SUCCESS,
	FETCH_BANK_PROVIDERS_FAILURE,
	FETCH_NEXT_BANK_PROVIDERS_REQUEST,
	FETCH_NEXT_BANK_PROVIDERS_SUCCESS,
	FETCH_NEXT_BANK_PROVIDERS_FAILURE,
	FETCH_PREV_BANK_PROVIDERS_REQUEST,
	FETCH_PREV_BANK_PROVIDERS_SUCCESS,
	FETCH_PREV_BANK_PROVIDERS_FAILURE,
} from "./types";

// Fetch Favourite Bank Providers
export const fetchFavouriteBankProvidersRequest = () => {
	return {
		type: FETCH_FAVOURITE_BANK_PROVIDERS_REQUEST,
	};
};

export const fetchFavouriteBankProvidersSuccess = (results) => {
	return {
		type: FETCH_FAVOURITE_BANK_PROVIDERS_SUCCESS,
		payload: results,
	};
};

export const fetchFavouriteBankProvidersFailure = (error) => {
	return {
		type: FETCH_FAVOURITE_BANK_PROVIDERS_FAILURE,
		payload: error,
	};
};

export const fetchFavouriteBankProviders = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchFavouriteBankProvidersRequest());

			const { data } = await bankService.getFavouriteBankProviders();
			dispatch(fetchFavouriteBankProvidersSuccess(data));
		} catch (ex) {
			dispatch(fetchFavouriteBankProvidersFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Fetch Bank Providers
export const fetchBankProvidersRequest = () => {
	return {
		type: FETCH_BANK_PROVIDERS_REQUEST,
	};
};

export const fetchBankProvidersSuccess = (results) => {
	return {
		type: FETCH_BANK_PROVIDERS_SUCCESS,
		payload: results,
	};
};

export const fetchBankProvidersFailure = (error) => {
	return {
		type: FETCH_BANK_PROVIDERS_FAILURE,
		payload: error,
	};
};

export const fetchBankProviders = (query) => {
	return async function (dispatch) {
		try {
			dispatch(fetchBankProvidersRequest());

			const { data } = await bankService.getBankProviders(query);
			dispatch(fetchBankProvidersSuccess(data));
		} catch (ex) {
			dispatch(fetchBankProvidersFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Fetch Next Bank Providers
export const fetchNextBankProvidersRequest = () => {
	return {
		type: FETCH_NEXT_BANK_PROVIDERS_REQUEST,
	};
};

export const fetchNextBankProvidersSuccess = (results) => {
	return {
		type: FETCH_NEXT_BANK_PROVIDERS_SUCCESS,
		payload: results,
	};
};

export const fetchNextBankProvidersFailure = (error) => {
	return {
		type: FETCH_NEXT_BANK_PROVIDERS_FAILURE,
		payload: error,
	};
};

export const fetchNextBankProviders = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchNextBankProvidersRequest());

			const { data } = await bankService.getNextBankProviders(store.getState().bank.providersData.providers.next);
			dispatch(fetchNextBankProvidersSuccess(data));
		} catch (ex) {
			dispatch(fetchNextBankProvidersFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Fetch Prev Bank Providers
export const fetchPrevBankProvidersRequest = () => {
	return {
		type: FETCH_PREV_BANK_PROVIDERS_REQUEST,
	};
};

export const fetchPrevBankProvidersSuccess = (results) => {
	return {
		type: FETCH_PREV_BANK_PROVIDERS_SUCCESS,
		payload: results,
	};
};

export const fetchPrevBankProvidersFailure = (error) => {
	return {
		type: FETCH_PREV_BANK_PROVIDERS_FAILURE,
		payload: error,
	};
};

export const fetchPrevBankProviders = () => {
	return async function (dispatch) {
		try {
			dispatch(fetchPrevBankProvidersRequest());

			const { data } = await bankService.getPrevBankProviders(
				store.getState().bank.providersData.providers.previous
			);
			dispatch(fetchPrevBankProvidersSuccess(data));
		} catch (ex) {
			dispatch(fetchPrevBankProvidersFailure(ex));
			console.log("Error: ", ex);
		}
	};
};
