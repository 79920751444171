// Fetch Help Articles Data
export const FETCH_HELP_ARTICLES_DATA_REQUEST = "FETCH_HELP_ARTICLES_DATA_REQUEST";
export const FETCH_HELP_ARTICLES_DATA_SUCCESS = "FETCH_HELP_ARTICLES_DATA_SUCCESS";
export const FETCH_HELP_ARTICLES_DATA_FAILURE = "FETCH_HELP_ARTICLES_DATA_FAILURE";

// Fetch More Help Articles Data
export const FETCH_MORE_HELP_ARTICLES_DATA_REQUEST = "FETCH_MORE_HELP_ARTICLES_DATA_REQUEST";
export const FETCH_MORE_HELP_ARTICLES_DATA_SUCCESS = "FETCH_MORE_HELP_ARTICLES_DATA_SUCCESS";
export const FETCH_MORE_HELP_ARTICLES_DATA_FAILURE = "FETCH_MORE_HELP_ARTICLES_DATA_FAILURE";

// Filter Help Articles Data
export const FILTER_HELP_ARTICLES_DATA = "FILTER_HELP_ARTICLES_DATA";
