import React, { forwardRef, useEffect, useState } from 'react'
import AutomationLoanTypeTag from './AutomationLoanTypeTag'
import moment from 'moment';
import Switch from "react-switch";
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import './AutomationRuleCard.css'
import MenuButton from '../../img/assets/menu-button.svg'
import EditIconMenu from '../../img/assets/edit-icon-menu.svg'
import TrashIconMenu from '../../img/assets/trash-icon-menu.svg'
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import CustomCard from './CustomCard';
import { deleteAutomationRules, putAutomationRules } from '../../services/automationService';
// Import Images
import InsufficientBalance from "../../img/assets/automation-insufficient-balance.svg";

const AutomationRuleCard = forwardRef(({id, ruleName, ruleStatus, dateCreated, handleEditExistingRule, loanType, offeredInterest, automateFund, onHold, refreshRules, isSkeleton}, ref) => {

  const [formattedDate, setFormattedDate] = useState('');
  const [switchChecked, setSwitchChecked] = useState(false);
  const [disabledSwitch, setDisabledSwitch] = useState(false);

  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const [visible, setVisible] = useState(false);
  const [deleteClicked, setDeleteClicked] = useState(false);
  

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {

    const inputDate = dateCreated;
    const formattedDate = moment(inputDate).format('MMM DD, YYYY hh:mm A');
  
    console.log('formattedDate ', formattedDate); // Outputs: "Jan 23, 2024, 3:09 AM"
    setFormattedDate(formattedDate)
  }, [dateCreated])

  // useEffect(() => {
  //   console.log('isSkeleton ', isSkeleton)
  // }, [])

  const handleSwitchChange = async (checked) => {
    console.log('handleSwitchChange_checked ', checked)
    setDisabledSwitch(true);

    const params = {
      rule_name: ruleName,
      loan_type: loanType,
      offered_interest: offeredInterest,
      automate_fund: automateFund,
      rule_status: checked ? "active" : "inactive"
    }
    console.log('params ', params)
    try {
      await putAutomationRules(id, params).then((res) => {
        console.log('putAutomationRules_SUCCESS ', res?.status)
        const results = res.data.results;
        console.log('putAutomationRules_SUCCESS_res ', results)
        // setLoanAutomations(results)
        refreshRules();
        setSwitchChecked(checked);
        setDisabledSwitch(false);
      })
    } catch (err) {
      console.log('putAutomationRules_CATCH_ERR ', err);
      setDisabledSwitch(false);
    }
  }
  const handleDeleteMenuButtonClicked = () => {
    handleClose();
    setDeleteClicked(true);
  }

  const handleEditMenuButtonClicked = () => {
    handleClose();
    handleEditExistingRule(id);
  }

  const handleDeleteRule = async () => {
    console.log('handleDeleteRule')

    setDisabledSwitch(true);

    const params = {
      rule_name: ruleName,
      loan_type: loanType,
      offered_interest: offeredInterest,
      automate_fund: automateFund,
      rule_status: "deleted"
    }
    try {
      await putAutomationRules(id, params).then((res) => {
        console.log('deleteAutomationRules_SUCCESS ', res?.status)
        const results = res.data.results;
        console.log('deleteAutomationRules_SUCCESS_res ', results)
        // setLoanAutomations(results)
        refreshRules();
        handleCancelCard();
        setDisabledSwitch(false);
      })
    } catch (err) {
      console.log('deleteAutomationRules_CATCH_ERR ', err)
      refreshRules();
      handleCancelCard();
      setDisabledSwitch(false);
    }
  }

  const handleCancelCard = () => {
    // setVisible(prev => !prev);
    setDeleteClicked(false);
  };

  if (isSkeleton) {
    return (

      <div ref={ref} className='automation-rule-card' style={{border: '1px solid #F1F2FB'}}>

        {/* Container */}
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          {/* Left Hand Section */}
          <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',  }}>

            {/* Title skeleton */}
            <div style={{ marginBottom: '16px', width: '35px', height: '21px', background: '#F1F2FB', borderRadius: '7px',}}></div>

            {/* Tags skeleton */}
            <div style={{
                display: 'flex', width: '42px', height: '16px', padding: '8px', 
                background: '#F1F2FB', borderRadius: '100px', marginBottom: '16px'
              }}
            >
            </div>

            <div style={{}} className='insufficient-balance-container'>

              <div style={{background: '#F1F2FB', width: '131px', height: '18px', borderRadius: '7px' }}></div>
              
            </div>
          </div>

          {/* Righ Hand Section */}
          <div style={{ }}  className='d-flex flex-column-reverse flex-sm-row align-items-center' >
            
            <div style={{width: '42px', height: '24px', background: '#F1F2FB', borderRadius: '12px', alignSelf: 'center'}}></div>

            <div className='edit-button-container' style={{marginLeft: '25px', padding: '16px'}} >

              <svg width="2" height="10" viewBox="0 0 2 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="1" cy="1" r="1" fill="#F1F2FB"/>
                <circle cx="1" cy="5" r="1" fill="#F1F2FB"/>
                <circle cx="1" cy="9" r="1" fill="#F1F2FB"/>
              </svg>

            </div>

          </div>
        </div>
      </div>
    )
  }

  return (
    <div ref={ref} className='automation-rule-card' style={{}}>

      {/* Container */}
      <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
        {/* Left Hand Section */}
        <div style={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'flex-start',  }}>

          <div style={{fontSize: '14px', marginBottom: '16px'}}>{ruleName}</div>
          <div style={{marginBottom: '16px', display: 'flex', flexDirection: 'row'}}>

            <AutomationLoanTypeTag value={ruleStatus ?? ''} />
            {loanType && loanType === 'both' ? (
              <>
                <AutomationLoanTypeTag value={'crypto'} />
                <AutomationLoanTypeTag value={'cash'} />
              </>
            ) : (

              <AutomationLoanTypeTag value={loanType ?? ''} />
            )}
          </div>

          <div style={{}} className='insufficient-balance-container'>

            <div style={{color: '#6C6C6C', fontSize: '12px', fontWeight: 400, fontFamily: 'Poppins' }}>{formattedDate ?? ''}</div>
            
              {onHold && (
                <div className='insufficient-balance' style={{ display: 'flex', flexDirection: 'row', alignItems: 'end',}}>
                  <img src={InsufficientBalance} alt="icon" />
                  <div style={{ marginLeft: '4px', color: '#E74646', fontSize: '8px', fontWeight: 500, fontFamily: 'Poppins' }}>On Hold due to Insufficient Balance</div>
                </div>
              )}
          </div>
        </div>

        {/* Righ Hand Section */}
        <div style={{ }}  className='d-flex flex-column-reverse flex-sm-row align-items-center' >

          <div className='switch-button'>

            <Switch width={42} height={24} 
              disabled={disabledSwitch}
              onChange={handleSwitchChange} 
              checked={ruleStatus === 'active'} 
              uncheckedIcon={false} 
              checkedIcon={false} 
              onColor='#7F5AF0' 
              offColor='#E3E3E3' 
              offHandleColor='#fff' 
              onHandleColor='#f1f1f1'  
            />
          </div>

          <div className='edit-button-container' >
            <IconButton
              aria-label="more"
              id="long-button"
              aria-controls={open ? 'long-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={handleClick}
              // style={{marginLeft: '25px'}}
              sx={{
                ":hover": {
                  bgcolor: "#EBE5FE",
                  // color: "white"
                }
              }}
              // style={{ position: 'absolute', top:  '6px', right:  '1px' }} 
              // className='edit-button'
              
            >
              <img src={MenuButton} alt="Image" style={{paddingInline: '5px'}} />
            </IconButton>
          </div>

          <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
          >
            <MenuItem onClick={handleEditMenuButtonClicked}>
              <ListItemIcon>
                <img src={EditIconMenu} alt="Image" style={{margin: '5px'}} />
              </ListItemIcon>
              <ListItemText style={{color: '#000', fontSize: '12px'}}>Edit</ListItemText>
            </MenuItem>

            <MenuItem onClick={handleDeleteMenuButtonClicked}>
              <ListItemIcon>
                <img src={TrashIconMenu} alt="Image" style={{margin: '5px'}} />
              </ListItemIcon>
              <ListItemText style={{color: '#E85658', fontSize: '12px'}}>Delete</ListItemText>
            </MenuItem>
            {/* <MenuItem onClick={handleClose}>My account</MenuItem>
            <MenuItem onClick={handleClose}>Logout</MenuItem> */}
          </Menu>
        </div>



      </div>

      {deleteClicked && (
        <CustomCard style={{position: 'relative', width: '100%', height: '100%', zIndex: 1000,}} onClick={handleCancelCard} >
          <div className='' style={{position: 'absolute', top: '-16px', right: '10px',}}>
            <div style={{background: '#FFF', width: '360px', height: 'fit-content', padding: '16px', border: '0.5px solid #E3E3E3', borderRadius:'10px', boxShadow: '0px 0px 15px 0px rgba(19, 21, 38, 0.10)'}}>
              <p style={{color: '#000', fontSize: '14px', fontWeight: 500, lineHeight: 'normal'}}>Delete Permanently</p>
              <div style={{color: '#727272', fontSize: '12px', fontWeight: 300, lineHeight: '18px'}}>{"Are you sure you want to delete this automation?"}
                <div > 
                  <span style={{color: '#000'}}>{ruleName} </span>
                will be deleted permanently.</div> 
                <div > {"You can’t undo this action."}</div> 

              </div>
              <div style={{height: '0.5px', background: '#E3E3E3', marginTop: '16px'}} />

              <div style={{marginTop: '16px', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                <button type='button' 
                  style={{width: '64px', height: '32px', border: 'none', background: 'none', marginRight: '12px'}} 
                  onClick={handleCancelCard}
                >
                  <p style={{margin: 'auto', color: '#131526', fontSize: '12px', fontWeight: 400, lineHeight: 'normal'}} >Cancel</p> 
                  
                </button>

                <button type='button' 
                  style={{width: '64px', height: '32px', borderRadius: '100px', border: '1px solid #131526', background: '#131526'}} 
                  onClick={handleDeleteRule}
                >
                  <p style={{margin: 'auto', color: '#FFF', fontSize: '12px', fontWeight: 600, lineHeight: 'normal'}} >Delete</p> 
                </button>
              </div>

            </div>
          </div>
        </CustomCard>
      )}
  
    </div>
  )
})

export default AutomationRuleCard