// Types
import { SHOW_TOAST, HIDE_TOAST } from "./types";

// Toast
export const showToast = (type, title, body, button, onButtonClick) => {
	return {
		type: SHOW_TOAST,
		payload: { type, title, body, button, onButtonClick },
	};
};

export const hideToast = (index) => {
	return {
		type: HIDE_TOAST,
		index,
	};
};
