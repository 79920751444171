import notificationService from "../../services/notificationService";

// Types
import {
	FETCH_HEADER_NOTIFICATIONS_DATA_REQUEST,
	FETCH_HEADER_NOTIFICATIONS_DATA_SUCCESS,
	FETCH_HEADER_NOTIFICATIONS_DATA_FAILURE,
	FETCH_NOTIFICATIONS_DATA_REQUEST,
	FETCH_NOTIFICATIONS_DATA_SUCCESS,
	FETCH_NOTIFICATIONS_DATA_FAILURE,
	FETCH_MORE_NOTIFICATIONS_DATA_REQUEST,
	FETCH_MORE_NOTIFICATIONS_DATA_SUCCESS,
	FETCH_MORE_NOTIFICATIONS_DATA_FAILURE,
} from "./types";

// Fetch Header Notifications Data
export const fetchHeaderNotificationsDataRequest = () => {
	return {
		type: FETCH_HEADER_NOTIFICATIONS_DATA_REQUEST,
	};
};

export const fetchHeaderNotificationsDataSuccess = notifications => {
	return {
		type: FETCH_HEADER_NOTIFICATIONS_DATA_SUCCESS,
		payload: notifications,
	};
};

export const fetchHeaderNotificationsDataFailure = error => {
	return {
		type: FETCH_HEADER_NOTIFICATIONS_DATA_FAILURE,
		payload: error,
	};
};

export const fetchHeaderNotificationsData = () => {
	return async function(dispatch) {
		try {
			dispatch(fetchHeaderNotificationsDataRequest());

			const { data } = await notificationService.getNotifications();
			dispatch(fetchHeaderNotificationsDataSuccess(data));
		} catch (ex) {
			dispatch(fetchHeaderNotificationsDataFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Fetch Notifications Data
export const fetchNotificationsDataRequest = () => {
	return {
		type: FETCH_NOTIFICATIONS_DATA_REQUEST,
	};
};

export const fetchNotificationsDataSuccess = notifications => {
	return {
		type: FETCH_NOTIFICATIONS_DATA_SUCCESS,
		payload: notifications,
	};
};

export const fetchNotificationsDataFailure = error => {
	return {
		type: FETCH_NOTIFICATIONS_DATA_FAILURE,
		payload: error,
	};
};

export const fetchNotificationsData = () => {
	return async function(dispatch) {
		try {
			dispatch(fetchNotificationsDataRequest());

			const { data } = await notificationService.getNotifications();
			dispatch(fetchNotificationsDataSuccess(data));
		} catch (ex) {
			dispatch(fetchNotificationsDataFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Fetch More Notifications Data
export const fetchMoreNotificationsDataRequest = () => {
	return {
		type: FETCH_MORE_NOTIFICATIONS_DATA_REQUEST,
	};
};

export const fetchMoreNotificationsDataSuccess = notifications => {
	return {
		type: FETCH_MORE_NOTIFICATIONS_DATA_SUCCESS,
		payload: notifications,
	};
};

export const fetchMoreNotificationsDataFailure = error => {
	return {
		type: FETCH_MORE_NOTIFICATIONS_DATA_FAILURE,
		payload: error,
	};
};

export const fetchMoreNotificationsData = apiEndpointForMoreNotifications => {
	return async function(dispatch) {
		try {
			dispatch(fetchMoreNotificationsDataRequest());

			const { data } = await notificationService.getMoreNotifications(apiEndpointForMoreNotifications);
			dispatch(fetchMoreNotificationsDataSuccess(data));
		} catch (ex) {
			dispatch(fetchMoreNotificationsDataFailure(ex));
			console.log("Error: ", ex);
		}
	};
};
