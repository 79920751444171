import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

// Redux DevTool
import { composeWithDevTools } from "redux-devtools-extension";

// Logger
// import logger from "redux-logger";

// Root Reducer
import rootReducer from "./rootReducer";

export default createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
