import React from "react";
import PropTypes from "prop-types";
import ReactDatePicker from "react-datepicker";

// Import Components
import LNFloatingInput from "../LNFloatingInput/LNFloatingInput";

// Import CSS
import "react-datepicker/dist/react-datepicker.css";
import "./LNDatePicker.css";

const LNDatePicker = React.forwardRef(
	({ size, optional, name, value, placeholder, text, onChange, dateFormat = "MMMM d, yyyy", error }, ref) => (
		<ReactDatePicker
			selected={value && new Date(value)}
			placeholderText={placeholder}
			onChange={onChange}
			dateFormat={dateFormat}
			showMonthDropdown
			showYearDropdown
			dropdownMode="select"
			name={name}
			autoComplete="off"
			withPortal
			error
			isClearable={false}
			customInput={<LNFloatingInput size={size} optional={optional} error={error} text={text} type={"datePicker"} />}
			ref={ref}
		/>
	)
);

// Prop Types
LNDatePicker.propTypes = {
	size: PropTypes.string,
	optional: PropTypes.bool,
	name: PropTypes.string,
	value: PropTypes.any,
	placeholder: PropTypes.string,
	text: PropTypes.string,
	onChange: PropTypes.func,
	dateFormat: PropTypes.string,
	error: PropTypes.string,
};

export default LNDatePicker;
