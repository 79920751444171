import React from "react";
import PropTypes from "prop-types";

// Images
import Spinner from "../../../img/spinners/offer_loading.gif";

// CSS
import "./showMore.css";

function ShowMore({ loading, handleClick }) {
	return (
		<div className="show-more">
			{!loading && (
				<button onClick={handleClick} className="btn">
					Show more
				</button>
			)}

			{loading && <img src={Spinner} alt="Loading..." width="55" />}
		</div>
	);
}

// Prop Types
ShowMore.propTypes = {
	loading: PropTypes.bool.isRequired,
	handleClick: PropTypes.func.isRequired,
};

export default ShowMore;
