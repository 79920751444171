import React, { Component } from "react";
import PropTypes from "prop-types";

// Import Services
import ownerService from "../../services/ownerService";

// Import CSS
import "./ownerCard.css";

class OwnerCard extends Component {
	// Helper Methods
	deleteOwner = async (id) => {
		try {
			await ownerService.deleteOwner(id);
			this.props.update_function();
		} catch (ex) {
			console.log("Error: ", ex);
		}
	};

	// Render
	render() {
		const { owner, edit_function } = this.props;

		// Render
		return (
			<div className="owner-card mb-3">
				<div className="p-3 border rounded">
					<div className="d-flex">
						<div className="square mr-3">■</div>
						<div>
							<div className="font-weight-bold mb-2">
								{owner.first_name} {owner.last_name}
							</div>
							<div className="mb-2">{owner.title}</div>
							<div className="mb-2">Ownership: {Math.round(owner.ownership_percentage)}%</div>
							{edit_function && (
								<div>
									<button
										onClick={() => this.deleteOwner(owner.id)}
										className="btn btn-link px-0 mr-3">
										Remove
									</button>
									<button onClick={edit_function} className="btn btn-link px-0">
										Edit
									</button>
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
		);
	}
}

// Prop Types
OwnerCard.propTypes = {
	owner: PropTypes.object.isRequired,
	update_function: PropTypes.func.isRequired,
	edit_function: PropTypes.func,
};

export default OwnerCard;
