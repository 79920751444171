import React, { Component } from "react";
import { connect } from "react-redux";
import { fetchLicenseData } from "../../redux";
import { Formik } from "formik";
import moment from "moment";
import * as Yup from "yup";

// Import Services
import http from "../../services/httpService";

// Import Utils
import { getRegionList } from "../../utils/handleRegions";

// Import Components
import LNFloatingInput from "../common/LNFloatingInput/LNFloatingInput";
import LNRadioInput from "../common/LNRadioInput/LNRadioInput";
import LNSelect from "../common/LNSelect/LNSelect";
import LNDatePicker from "../common/LNDatePicker/LNDatePicker";
import LNUpload from "../common/LNUpload/LNUpload";

// Import Images
import ArrowRight from "../../img/icons/black/arrow-right.svg";

// Import CSS
import "./editLicenseInfo.css";

class EditLicenseInfo extends Component {
	render() {
		// Validations
		const ValidationSchema = Yup.object().shape({
			number: Yup.string().max(255, "Too Long!"),
			issue_date: Yup.string().test("issue_date", "Please verify your input is correct.", value => {
				if (value === undefined) {
					return true;
				}
				return moment().diff(moment(new Date(value)), "days") > 0;
			}),
			expiry_date: Yup.string().test("expiry_date", "Please verify your input is correct.", value => {
				if (value === undefined) {
					return true;
				}
				return moment().diff(moment(new Date(value)), "days") < 1;
			}),
		});

		const { licenseData, reset_view_func } = this.props;

		// Check if loading
		if (licenseData.loading) return <div>Loading...</div>;

		// Render
		return (
			<div>
				<div className="d-flex flex-row align-items-center">
					<div
						className="profile-page-link"
						onClick={() => {
							reset_view_func();
						}}>
						Profile Page
					</div>
					<img src={ArrowRight} alt="Arrow Right" className="profile-page-arrow" />
					<div className="profile-page-link-name">Lending License Information</div>
				</div>
				<div className="row mt-5">
					<div className="col-6 offset-2">
						<div className="profile-section-title">Lending License Information</div>
						<Formik
							initialValues={{
								have_license: 0,
								state: "",
								type: "",
								file: "",
								number: "",
								issue_date: "",
								expiry_date: "",
							}}
							validationSchema={ValidationSchema}
							onSubmit={(values, { setSubmitting, setErrors }) => {
								setSubmitting(true);
								const postData = {
									number: values.number,
									type: values.type,
									state_of_issuance: values.state,
									issue_date: values.issue_date,
									expiry_date: values.expiry_date,
									file: values.file,
								};
								http.post(`enterprise/v${process.env.REACT_APP_API_VERSION}/license/`, postData)
									.then(res => {
										setSubmitting(false);
										reset_view_func();
									})
									.catch(error => {
										console.log("Error: ", error);
										setErrors(error.response.data);
										setSubmitting(false);
									});
							}}>
							{({
								values,
								errors,
								touched,
								handleChange,
								handleBlur,
								handleSubmit,
								isSubmitting,
								setFieldValue,
								setSubmitting,
							}) => (
								<form onSubmit={handleSubmit} spellCheck={false}>
									<div className="inputs-wrapper">
										<div className="mb-2">
											<div className="mt-3">
												<LNSelect
													name="state"
													size="lg"
													value={values.state}
													onChange={handleChange}
													onBlur={handleBlur}
													defaultOption={{ name: "State of issuance", value: "" }}
													options={getRegionList("US")}
												/>

												<div className="mt-3">
													<span className="text-muted d-block mb-3">License type</span>

													<div className="mb-2">
														<div className="mb-2">
															<LNRadioInput
																name="type"
																label="Lending License"
																value="lending"
																onChange={handleChange}
															/>
														</div>

														{values.type === "lending" && (
															<LNUpload
																document_type="lending"
																change_function={file_id => {
																	setFieldValue("file", file_id);
																}}
																set_loading={state => {
																	setSubmitting(state);
																}}
															/>
														)}
													</div>

													<div className="mb-2">
														<div className="mb-2">
															<LNRadioInput
																name="type"
																label="Broker License"
																value="broker"
																onChange={handleChange}
															/>
														</div>

														{values.type === "broker" && (
															<LNUpload
																document_type="broker"
																change_function={file_id => {
																	setFieldValue("file", file_id);
																}}
																set_loading={state => {
																	setSubmitting(state);
																}}></LNUpload>
														)}
													</div>
												</div>

												{values.type && (
													<>
														<LNFloatingInput
															size="lg"
															type="text"
															name="number"
															value={values.number}
															onChange={handleChange}
															onBlur={handleBlur}
															placeholder="License Number"
															error={
																touched.number && errors.number
																	? `* ${errors.number}`
																	: ""
															}
														/>

														<LNDatePicker
															size="lg"
															optional={false}
															name="issue_date"
															value={values.issue_date}
															placeholder="Issue Date"
															dateFormat="MM/dd/yyyy"
															onChange={date =>
																setFieldValue(
																	"issue_date",
																	moment(date).format("YYYY-MM-DD")
																)
															}
															error={
																touched.issue_date && errors.issue_date
																	? `* ${errors.issue_date}`
																	: ""
															}
														/>

														<LNDatePicker
															size="lg"
															optional={false}
															name="expiry_date"
															value={values.expiry_date}
															placeholder="Expiry Date"
															dateFormat="MM/dd/yyyy"
															onChange={date =>
																setFieldValue(
																	"expiry_date",
																	moment(date).format("YYYY-MM-DD")
																)
															}
															error={
																touched.expiry_date && errors.expiry_date
																	? `* ${errors.expiry_date}`
																	: ""
															}
														/>
													</>
												)}
											</div>
										</div>
									</div>

									<div
										className="btn btn-lg btn-black w-100 my-5"
										onClick={handleSubmit}
										disabled={isSubmitting}>
										Save
									</div>
								</form>
							)}
						</Formik>
					</div>
				</div>
			</div>
		);
	}
}

function mapStateToProps(state) {
	return {
		licenseData: state.user.licenseData,
	};
}

function mapDispatchToProps(dispatch) {
	return {
		fetchLicenseData: () => dispatch(fetchLicenseData()),
	};
}
export default connect(mapStateToProps, mapDispatchToProps)(EditLicenseInfo);
