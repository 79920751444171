import React from "react";
import PropTypes from "prop-types";

// Images
import Success from "../../../img/static/success.svg";
import Error from "../../../img/static/error.svg";

// CSS
import "./responseIllustration.css";

function ResponseIllustration({ type, width }) {
	if (type === "success") return <img src={Success} alt="Success" width={width} />;
	if (type === "error") return <img src={Error} alt="Error" width={width} />;
	return null;
}

// Prop Types
ResponseIllustration.propTypes = {
	type: PropTypes.string.isRequired,
	width: PropTypes.number,
};

export default ResponseIllustration;
