import { getFeed, getFeedNext } from "../../services/feedService";
import store from "../store";

// Types
import {
	FETCH_FEED_DATA_REQUEST,
	FETCH_FEED_DATA_SUCCESS,
	FETCH_FEED_DATA_FAILURE,
	FETCH_FEED_NEXT_REQUEST,
	FETCH_FEED_NEXT_SUCCESS,
	FETCH_FEED_NEXT_FAILURE,
} from "./types";

// Fetch Feed Data
export const fetchFeedDataRequest = () => {
	return {
		type: FETCH_FEED_DATA_REQUEST,
	};
};

export const fetchFeedDataSuccess = feed => {
	return {
		type: FETCH_FEED_DATA_SUCCESS,
		payload: feed,
	};
};

export const fetchFeedDataFailure = error => {
	return {
		type: FETCH_FEED_DATA_FAILURE,
		payload: error,
	};
};

export const fetchFeedData = () => {
	return async function(dispatch) {
		try {
			dispatch(fetchFeedDataRequest());

			const { data } = await getFeed();
			dispatch(fetchFeedDataSuccess(data));
		} catch (ex) {
			dispatch(fetchFeedDataFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Fetch Feed Next
export const fetchFeedNextRequest = () => {
	return {
		type: FETCH_FEED_NEXT_REQUEST,
	};
};

export const fetchFeedNextSuccess = feed => {
	return {
		type: FETCH_FEED_NEXT_SUCCESS,
		payload: feed,
	};
};

export const fetchFeedNextFailure = error => {
	return {
		type: FETCH_FEED_NEXT_FAILURE,
		payload: error,
	};
};

export const fetchFeedNext = () => {
	return async function(dispatch) {
		try {
			dispatch(fetchFeedNextRequest());
			const { data } = await getFeedNext(store.getState().feed.feed.next);
			dispatch(fetchFeedNextSuccess(data));
		} catch (ex) {
			dispatch(fetchFeedNextFailure(ex));
			console.log("Error: ", ex);
		}
	};
};
