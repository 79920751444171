import React, { useState } from "react";
import PropTypes from "prop-types";
import { NavLink } from "react-router-dom";

// Images
import DownArrow from "../../img/icons/white/down-arrow.svg";

// CSS
import "./mobileNav.css";

function MobileNav({ title, items }) {
	const [showAllItems, setShowAllItems] = useState(false);
	const [currentTabName, setCurrentTabName] = useState(items ? items[0].name : null);

	// Helper Methods
	const handleTabClick = item => {
		setCurrentTabName(item.name);
		setShowAllItems(false);
	};

	return (
		<div className="mobile-nav d-sm-none py-2">
			<div onClick={() => setShowAllItems(!showAllItems)} className="title px-3 py-2 pointer">
				<span className="mr-2">{title}</span>
				{items && <img src={DownArrow} alt="Down" />}
			</div>

			{items && (
				<div className="items">
					{/* Current Item */}
					{!showAllItems && <div className="px-3 py-2 small">{currentTabName}</div>}

					{/* All Items */}
					{showAllItems &&
						items.map(item => (
							<NavLink
								onClick={() => handleTabClick(item)}
								key={item.id}
								to={item.path}
								className="px-3 py-2 pointer small"
								end>
								{item.name}
							</NavLink>
						))}
				</div>
			)}
		</div>
	);
}

// Prop Types
MobileNav.propTypes = {
	title: PropTypes.string.isRequired,
	items: PropTypes.array,
};

export default MobileNav;
