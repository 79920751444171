import React, { useState } from "react";
import { NavLink, useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import history from "../../history";

// Import Images
import CloseIcon from "../../img/icons/white/close.svg";

// Import CSS
import "./sideBar.css";

const SideBar = ({ handleClose }) => {
	const [investmentCollapsed, setInvestmentCollapsed] = useState(true);
	const location = useLocation();
  	const { hash, pathname, search } = location;

	const handleSetCurrentSideBar = (current, e) => {
		console.log('handleSetCurrentSideBar_current ', current)
		handleClose(e)
	}
	console.log('pathname ', pathname)
	// Render
	return (
		<div id="sidebar" className="sidebar d-none d-md-block">
			<div onClick={handleClose} className="overlay"></div>

			<div className="items">
				<div onClick={handleClose} className="close">
					<img src={CloseIcon} alt="x" />
				</div>

				<NavLink to="/user/notification" className="sidebar-item d-sm-none" onClick={handleClose}>
					{pathname.startsWith("/user/notification") ? (
						<img src="/sidebar-icons/black/notifications.svg" alt="Icon" />
					):
					(
						<img src="/sidebar-icons/white/notifications.svg" alt="Icon" />
					)}
					<span>Notifications</span>
				</NavLink>

				<NavLink to="/user/dashboard" className="sidebar-item" onClick={(e) => handleSetCurrentSideBar('dashboard', e)}>
					{pathname.startsWith("/user/dashboard") ? (
						<img src="/sidebar-icons/black/dashboard.svg" alt="Icon" />
					):
					(
						<img src="/sidebar-icons/white/dashboard.svg" alt="Icon" />
					)}
					<span>Dashboard</span>
				</NavLink>

				<NavLink to="/user/feed" className="sidebar-item d-md-none" onClick={handleClose}>
					{pathname.startsWith("/user/feed") ? (
						<img src="/sidebar-icons/black/feed.svg" alt="Icon" />
					):
					(
						<img src="/sidebar-icons/white/feed.svg" alt="Icon" />
					)}
					<span>Feed</span>
				</NavLink>

				<NavLink to="/user/loans" className="sidebar-item " onClick={(e) => handleSetCurrentSideBar('loans', e)}>
					{pathname.startsWith("/user/loans") ? (
						<img src="/sidebar-icons/black/investment.svg" alt="Icon" />
					):
					(
						<img src="/sidebar-icons/white/investment.svg" alt="Icon" />
					)}
					<span>Loans</span>
				</NavLink>

				<section
					onClick={() => setInvestmentCollapsed(!investmentCollapsed)}
					className="sidebar-section d-none">
					<div
						to="/user/loans"
						className={`sidebar-item ${
							history.location.pathname.startsWith("/user/loans") ? "active" : ""
						}`}>
						<img src="/sidebar-icons/black/investment.svg" alt="Icon" />
						<span>Loans</span>
					</div>

					{!investmentCollapsed && (
						<div>
							<NavLink to="/user/loans" className="sidebar-item" onClick={handleClose} end>
								All
							</NavLink>

							<NavLink to="/user/loans/processing" className="sidebar-item" onClick={handleClose}>
								Processing
							</NavLink>

							<NavLink to="/user/loans/current" className="sidebar-item" onClick={handleClose}>
								Current
							</NavLink>

							<NavLink to="/user/loans/completed" className="sidebar-item" onClick={handleClose}>
								Completed
							</NavLink>

							<NavLink to="/user/loans/collections" className="sidebar-item" onClick={handleClose}>
								Collections
							</NavLink>
						</div>
					)}
				</section>

				<NavLink to="/user/bank-transfer" className="sidebar-item" onClick={(e) => handleSetCurrentSideBar('bank-transfer', e)}>
					{pathname.startsWith("/user/bank-transfer") ? (
						<img src="/sidebar-icons/black/bank-transfer.svg" alt="Icon" />
					):
					(
						<img src="/sidebar-icons/white/bank-transfer.svg" alt="Icon" />
					)}
					<span>Bank Transfer</span>
				</NavLink>

				<NavLink to="/user/automation" className="sidebar-item" onClick={(e) => handleSetCurrentSideBar('automation', e)}>
					{pathname.startsWith("/user/automation") ? (
						<img src="/sidebar-icons/black/automation-icon.svg" alt="Icon" />
					):
					(
						<img src="/sidebar-icons/white/automation-icon.svg" alt="Icon" />
					)}
					<span>Automation</span>
				</NavLink>

				<NavLink to="/user/settings" className="sidebar-item" onClick={(e) => handleSetCurrentSideBar('settings', e)}>
					{pathname.startsWith("/user/settings") ? (
						<img src="/sidebar-icons/black/settings.svg" alt="Icon" />
					):
					(
						<img src="/sidebar-icons/white/settings.svg" alt="Icon" />
					)}
					<span>Settings</span>
				</NavLink>

				<NavLink to="/user/support" className="sidebar-item" onClick={(e) => handleSetCurrentSideBar('support', e)}>
					{pathname.startsWith("/user/support") ? (
						<img src="/sidebar-icons/black/support.svg" alt="Icon" />
					):
					(
						<img src="/sidebar-icons/white/support.svg" alt="Icon" />
					)}
					<span>Support</span>
				</NavLink>

				<NavLink to="/logout" className="sidebar-item mb-0">
					<img src="/sidebar-icons/white/logout.svg" alt="Icon" />
					<span>Logout</span>
				</NavLink>
			</div>
		</div>
	);
};

// Prop Types
SideBar.propTypes = {
	handleClose: PropTypes.func.isRequired,
};

export default SideBar;
