import React, { Component } from "react";
import PropTypes from "prop-types";
import { Formik } from "formik";
import * as Yup from "yup";

// Import Services
import http from "../../../services/httpService";

// Import Utils
import { handleReactGAEvents } from "../../../utils/handleReactGAEvents";

// Import Components
import LNFloatingInput from "../../common/LNFloatingInput/LNFloatingInput";
import LNError from "../../common/LNError/LNError";

// Import CSS
import "./personalInfo.css";
import { Link } from "react-router-dom";

class PersonalInfo extends Component {
	state = {
		data: {
			first_name: "",
			middle_name: "",
			last_name: "",
		},
		error: false,
		errorMessages: [],
		pressCount: 0,

	};

	componentDidMount() {
		const loadCount = async () => {
		  try {
			const storedCount = await localStorage.getItem('pressCount');
			if (storedCount) {
			  console.log("parseInt(storedCount)",storedCount);
			  this.setState({ pressCount: parseInt(storedCount, 10) });
			}
		  } catch (error) {
			console.error('Error loading press count:', error);
		  }
		};
		loadCount();
	  }
	
	// Helper Methods
	handleSaveAndExit = async (handleSubmit, values) => {

		const { first_name, last_name } = values;
		if (first_name === "" || last_name === "") {
			this.handlePress()
			window.location = "/";	
			return
		}else{
			await handleSubmit();
		}
	};

	handleSubmit = async (values, { setSubmitting, setErrors }) => {
		try {
			setSubmitting(true);

			const postData = {
				first_name: values.first_name,
				middle_name: values.middle_name,
				last_name: values.last_name,
			};

			Object.keys(postData).forEach((key) => {
				if (!postData[key]) delete postData[key];
			});

			await http.post(`enterprise/v${process.env.REACT_APP_API_VERSION}/register/personal_info/`, postData);

			this.setState({ error: false });
			setSubmitting(false);

			this.props.incrementfunction();

			// Send GA event
			handleReactGAEvents("Sign_up_step_1");
		} catch (ex) {
			console.log("Error: ", ex);

			this.setState({
				error: true,
				errorMessages: ex.response.data,
			});

			setErrors(ex.response.data);
			setSubmitting(false);
		}
	};

	// Render
	render() {
		const { data, error, errorMessages } = this.state;

		// Validations
		const ValidationSchema = Yup.object().shape({
			first_name: Yup.string().max(255, "Too Long!").required("Required field"),
			middle_name: Yup.string().max(255, "Too Long!"),
			last_name: Yup.string().max(255, "Too Long!").required("Required field"),
		});

		// Render
		return (
			<div className="signup-process-form personal-info">
				{/* header */}
				<div className="form-header mb-5">
					<div className="main-form-label">Start lending with Lenme Enterprise</div>
					<div>Let's start with the basics.</div>
				</div>

				{/* Form */}
				<Formik initialValues={data} validationSchema={ValidationSchema} onSubmit={this.handleSubmit}>
					{({ values, errors, touched, handleChange, handleBlur, handleSubmit, isSubmitting }) => (
						<form onSubmit={handleSubmit}>
							<div className="inputs-wrapper">
								{error && <LNError text={errorMessages} />}

								<LNFloatingInput
									size="lg"
									type="text"
									optional={false}
									name="first_name"
									value={values.first_name}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="First Name"
									error={touched.first_name && errors.first_name ? `* ${errors.first_name}` : ""}
								/>

								<LNFloatingInput
									size="lg"
									type="text"
									optional={true}
									name="middle_name"
									value={values.middle_name}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Middle Name"
									error={touched.middle_name && errors.middle_name ? `* ${errors.middle_name}` : ""}
								/>

								<LNFloatingInput
									size="lg"
									type="text"
									optional={false}
									name="last_name"
									value={values.last_name}
									onChange={handleChange}
									onBlur={handleBlur}
									placeholder="Last Name"
									error={touched.last_name && errors.last_name ? `* ${errors.last_name}` : ""}
								/>
							</div>

							<div className="d-flex mt-5 buttons-wrapper">
								<div className="mr-3">
									<Link
										to="/"
										type="submit"
										className="btn btn-white btn-lg btn-block"
										onClick={async () => {
											const newCount = this.state.pressCount + 1;
											this.setState({ pressCount: newCount });
											try {
												await localStorage.setItem('pressCount', newCount.toString());
													console.log('Press count saved to storage:', newCount);
												} 
											catch (error) {
												console.error('Error saving press count:', error);
												}}
										} 
										disabled={isSubmitting}>
										Save and Exit
									</Link>
								</div>

								<div className="flex-grow-1">
									<button
										type="submit"
										className="btn btn-primary btn-lg btn-block"
										onClick={handleSubmit}
										disabled={isSubmitting}>
										Next
									</button>
								</div>
							</div>
						</form>
					)}
				</Formik>
			</div>
		);
	}
}

// Prop Types
PersonalInfo.propTypes = {
	incrementfunction: PropTypes.func.isRequired,
};

export default PersonalInfo;
