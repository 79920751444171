import http from "./httpService";

const apiEndpoint = `enterprise/v${process.env.REACT_APP_API_VERSION}`;
const accessTokenKey = "accessToken";
const refreshTokenKey = "refreshToken";

// Register
export async function register(data) {
	const response = await http.post(apiEndpoint + "/register/", {
		company_name: data.companyName,
		email: data.email,
		password: data.password,
		confirm_password: data.confirmPassword,
	});

	// Store token in localStorage
	localStorage.setItem(accessTokenKey, response.data.token);
	localStorage.setItem(refreshTokenKey, response.data.refresh);
}

// Get registration step
export async function getRegistrationStep() {
	return await http.get(apiEndpoint + "/register/registration_step/");
}

export default {
	register,
	getRegistrationStep,
};
