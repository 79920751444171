// Get Help Articles
export async function getHelpArticles() {
	const response = await fetch("https://lenmo.zendesk.com/api/v2/help_center/en-us/articles.json?per_page=100", {
		method: "GET",
	});

	return response.json();
}

export default {
	getHelpArticles,
};
