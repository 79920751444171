import ReactGA from "react-ga";

export function handleReactGAEvents(label, category, action, value, nonInteraction, transport) {
	ReactGA.event({
		label,
		category,
		action,
		value,
		nonInteraction,
		transport,
	});
}
