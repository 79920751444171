import React from "react";
import PropTypes from "prop-types";

// Components
import BackIcon from "../backIcon/backIcon";

// CSS
import "./popupHeader.css";

function PopupHeader({ title, handleBackClick }) {
	return (
		<div className="d-flex">
			<BackIcon handleClick={handleBackClick} />
			<div className="popup-header-title pl-3">{title}</div>
		</div>
	);
}

// Prop Types
PopupHeader.propTypes = {
	title: PropTypes.string.isRequired,
	handleBackClick: PropTypes.func.isRequired,
};

export default PopupHeader;
