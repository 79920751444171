import React from "react";
import PropTypes from "prop-types";
import moment from "moment";

// Components
import InfoIcon from "../common/infoIcon/infoIcon";
import PopupHeader from "../common/popupHeader/popupHeader";
import LNAvatar from "../common/LNAvatar/LNAvatar";

// CSS
import "./requestsHistory.css";

function RequestsHistory({
	visible_function,
	borrower_history,
	borrower_credit_score,
	borrower_lenmo_score,
	borrower_avatar_icon,
	borrower_avatar_color,
	borrower_avatar_initials,
}) {
	// Render
	return (
		<div className="d-flex align-items-center flex-wrap position-relative">
			<PopupHeader title="Lenme History" handleBackClick={() => visible_function("requestData")} />

			<div className="d-flex w-100 m-3 pr-3">
				<LNAvatar
					data={[
						{
							icon: borrower_avatar_icon,
							color: borrower_avatar_color,
							initials: borrower_avatar_initials,
						},
					]}
				/>

				<div className="card borrower-info-card d-flex flex-col flex-grow-1 ml-3 p-3">
					<div className="d-flex flex-row">
						<div className="card-item my-2">
							<div className="request-sub-title">Credit Score</div>
							<div>{borrower_credit_score}</div>
						</div>
						<div className="card-item my-2">
							<div className="request-sub-title">
								Lenme Score
								<InfoIcon handleClick={() => visible_function("lenmeScore")} classes="ml-2" />
							</div>
							<div>{borrower_lenmo_score}</div>
						</div>
					</div>

					<div className="d-flex vantage-score mt-3 mb-2">
						<div>VantageScore 4.0</div>
						<InfoIcon handleClick={() => visible_function("vantageScore")} classes="ml-2" />
					</div>
				</div>
			</div>
			<hr className="w-100" />

			<div className="d-flex flex-column w-100">
				{borrower_history.results.map((loan) => (
					<div key={loan.id}>
						<div className="m-3 flex-grow-1 d-flex">
							<LNAvatar
								double={true}
								data={[
									{
										icon: loan.lender.avatar,
										color: 4,
									},
									{
										icon: borrower_avatar_icon,
										color: borrower_avatar_color,
									},
								]}
							/>
							<div className="card borrower-info-card d-flex flex-col flex-grow-1 ml-3 p-3 mr-3">
								<div className="d-flex flex-row">
									<div className="card-item my-2">
										<div className="request-sub-title">Amount</div>
										<div>${loan.loan_amount}</div>
									</div>
									<div className="card-item my-2">
										<div className="request-sub-title">Payback Period</div>
										<div>
											{loan.payment_terms} {loan.payment_terms > 1 ? "Months" : "Month"}
										</div>
									</div>
								</div>
								<div className="d-flex flex-row">
									<div className="card-item my-2">
										<div className="request-sub-title">Investor</div>
										<div>{loan.lender.initial}</div>
									</div>
									<div className="card-item my-2">
										<div className="request-sub-title">Loan Completed</div>
										<div>{moment(loan.status_changed).format("MMM D, YYYY")}</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				))}
			</div>
		</div>
	);
}

// Prop Types
RequestsHistory.propTypes = {
	user_id: PropTypes.number.isRequired,
	visible_function: PropTypes.func.isRequired,
	borrower_history: PropTypes.object.isRequired,
	borrower_credit_score: PropTypes.string.isRequired,
	borrower_lenmo_score: PropTypes.number.isRequired,
	borrower_avatar_icon: PropTypes.number.isRequired,
	borrower_avatar_color: PropTypes.number.isRequired,
	borrower_avatar_initials: PropTypes.string.isRequired,
};

export default RequestsHistory;
