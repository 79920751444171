import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchFavLoanRequests, fetchLoanRequests, fetchMoreLoanRequests } from "../../redux";

// Components
import RequestCard from "../requestCard/requestCard";
import ShowMore from "../common/showMore/showMore";

// Images
import RequestsEmpty from "../../img/static/requests_empty.svg";
import LoanCardsLoadingBlocks from "../../img/spinners/blocks/loan cards.svg";
import FlipMove from 'react-flip-move';
// CSS
import "./requests.css";
import store from "../../redux/store";

function Requests() {
	const loanRequests = useSelector((state) => state.dashboard.loanRequests);
	const { results: requests = [] } = loanRequests.data;
	// const [requests, setRequests] = useState(loanRequests?.data?.results)

	const favLoanRequests = useSelector((state) => state.dashboard.favLoanRequests);
	const _dismissLoan = useSelector((state) => state.dashboard.dismissLoan);
	const _offerLoan = useSelector((state) => state.dashboard.offerLoan);
	
	// const { results: favRequests = [] } = favLoanRequests.data;
	const [favRequests, setFavRequests] = useState(favLoanRequests?.data?.results)

	const [refresh, setRefresh] = useState(true)
	const [loanRequestsLoading, setLoanRequestsLoading] = useState(true)
	const [loanRequestss, setLoanRequestss] = useState([]);
	// console.log('favLoanRequests ', favLoanRequests)
	// console.log('favRequests ', favRequests)

	const dispatch = useDispatch();

	// When Mount
	useEffect(() => {
		dispatch(fetchLoanRequests());
		dispatch(fetchFavLoanRequests())

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		setLoanRequestss(requests)
		
	}, []);

	useEffect(() => {
		// console.log('_dismissLoan ', _dismissLoan)
		// console.log('_offerLoan ', _offerLoan)
		if (!_dismissLoan.loading || !_offerLoan.loading) {
			setTimeout(() => {

				setLoanRequestss(requests)
			}, 200)
		}
	}, [_dismissLoan, _offerLoan, requests])

	useEffect(() => {
		
		if (loanRequests?.loading) {
			setLoanRequestsLoading(true);
			setTimeout(() => {
			  console.log('Check completed after 1 second');
			  setLoanRequestsLoading(false);
			}, 1000);
		}
	 }, [loanRequests]);

	// useEffect(()=>{
    //     document.addEventListener("refreshFavLoans", () => dispatch(fetchFavLoanRequests()));
    // },[])
	const refreshFav = async () => {
		// setRefresh(fals)
		console.log("useEffect_favRequests ", favRequests)
		
		// await store.dispatch(fetchLoanRequests());
		await store.dispatch(fetchFavLoanRequests())
		console.log('refreshFav_store.getState().dashboard.favLoanRequests.data ', store.getState().dashboard.favLoanRequests.data)
		
		// setFavRequests(favLoanRequests?.data?.results)
		// setRefresh(false)
	}

	const getFavReq = (id) => {
		if (favRequests && favRequests?.length > 0) {
			const foundIndex = favRequests.findIndex((favRequest) => favRequest.id === id);
			if (foundIndex !== -1) return favRequests[foundIndex]
			else return null
		}
		else return null
	}

	const handleRefresh = () => {
		refreshFav()
	}

	// Check if loading
	if (loanRequestsLoading) {
		// console.log('loanRequests ', loanRequests)
		// console.log('requests ', requests)
		// console.log('favLoanRequests ', favLoanRequests)
		
		return (
			<div>
				{/* <img src={LoanCardsLoadingBlocks} alt="Loading..." className="w-100" /> */}
				{/* <div className="title">Processing loans</div> */}
				<div className="body">
					<div>
						{requests?.slice(0, 5)?.map((request) => (

							<div key={request?.id} className="card request p-3 mt-3" style={{width: '100%', height: '163px', }}>
								<div className="d-flex justify-content-start">

									<div className="d-flex justify-content-center position-relative mr-3">

										<div className="avatar" style={{background: '#F1F2FB', borderRadius: '50%'}}>

										</div>
										{/* {initials_01 && <div className="avatar-initials px-2">{initials_01}</div>} */}
										<div className="avatar-initials ml-0" style={{background: '#F1F2FB', }}></div>
									</div>
									<div className="d-flex flex-wrap">
										<div className="card-item my-2">
											<div className="request-sub-title" style={{width: '60px', height: '18px', background: '#F1F2FB', borderRadius: '7px', marginBottom: '1px'}}></div>
											<div style={{width: '70px', height: '20px', background: '#F1F2FB', borderRadius: '7px'}}></div>
										</div>
										<div className="card-item my-2">
											<div className="request-sub-title" style={{width: '60px', height: '18px', background: '#F1F2FB', borderRadius: '7px', marginBottom: '1px'}}></div>
											<div style={{width: '70px', height: '20px', background: '#F1F2FB', borderRadius: '7px'}}></div>
										</div>
										<div className="card-item my-2">
											<div className="request-sub-title" style={{width: '60px', height: '18px', background: '#F1F2FB', borderRadius: '7px', marginBottom: '1px'}}></div>
											<div className="d-flex align-items-center">
												{/* <img src={creditScoreIcon} alt={credit_score} /> */}
												{/* <span className="ml-2">{credit_score}</span> */}
												<div style={{width: '10px', height: '10px', borderRadius: '50%', background: '#F1F2FB'}}></div>
												<span className="ml-2" style={{width: '70px', height: '20px', background: '#F1F2FB', borderRadius: '7px'}}></span>
											</div>
										</div>
										<div className="card-item my-2">
											{/* <div className="request-sub-title">Lenme Score</div> */}
											<div className="request-sub-title" style={{width: '60px', height: '18px', background: '#F1F2FB', borderRadius: '7px', marginBottom: '1px'}}></div>
											{/* <div>32</div> */}
											<div style={{width: '70px', height: '20px', background: '#F1F2FB', borderRadius: '7px'}}></div>
										</div>
									</div>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		);
	}

	// Zero State
	if (requests?.length === 0) {
		return (
			<div className="requests-zero-state mt-3">
				<div> We were unable to find any results. </div>
				<div> Please adjust your search.</div>
				<img className="pt-5" src={RequestsEmpty} alt="No Requests Found" />
			</div>
		);
	}	

	// Render
	return (
		<div>
			<FlipMove
				staggerDurationBy="20"
				duration={500}
				enterAnimation="elevator"
				leaveAnimation="elevator"
				typeName="div"
				style={{margin: 0, padding: 0}}
			>
			{loanRequestss.map((item) => (
				<RequestCard key={item.id} data={item} favRequests={favRequests}  handleRefresh={handleRefresh} />
			))}
			</FlipMove>

			{loanRequests.data.next && (
				<ShowMore
					loading={loanRequests.paginate.loading}
					handleClick={() => dispatch(fetchMoreLoanRequests())}
				/>
			)}
		</div>
	);

}

export default Requests;
