import http from "./httpService";

const appVersion = process.env.REACT_APP_API_VERSION;
const apiEndpoint = "/v" + appVersion + "/funding_resources/";

// Get User Funding Resources
export async function getUserFundingResources() {
	return await http.get(apiEndpoint);
}

// Transfer Lenme Balance to primary bank account
export async function transferBalanceToBank(amount) {
	return await http.post(apiEndpoint + "cash_out/", {
		amount: amount,
	});
}

// Transfer Balance to Lenme Balance from primary bank account
export async function transferBalanceToLenme(amount) {
	return await http.post(apiEndpoint + "fund_balance/", {
		amount: amount,
	});
}

export default {
	getUserFundingResources,
	transferBalanceToBank,
	transferBalanceToLenme,
};
