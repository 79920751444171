import { Component } from "react";

// Import Service
import auth from "../../services/authService";

class Logout extends Component {
	// Handle Logout
	handleLogout() {
		auth.logout();
		window.location = "/";
	}

	async componentDidMount() {
		// Handle logout
		this.handleLogout();
	}

	render() {
		return null;
	}
}

export default Logout;
