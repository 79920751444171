// Types
import {
	FETCH_LOAN_REQUESTS_REQUEST,
	FETCH_LOAN_REQUESTS_SUCCESS,
	FETCH_LOAN_REQUESTS_FAILURE,
	FETCH_MORE_LOAN_REQUESTS_REQUEST,
	FETCH_MORE_LOAN_REQUESTS_SUCCESS,
	FETCH_MORE_LOAN_REQUESTS_FAILURE,
	FETCH_FILTERED_LOAN_REQUESTS_REQUEST,
	FETCH_FILTERED_LOAN_REQUESTS_SUCCESS,
	FETCH_FILTERED_LOAN_REQUESTS_FAILURE,
	FETCH_FAV_LOAN_REQUESTS_REQUEST,
	FETCH_FAV_LOAN_REQUESTS_SUCCESS,
	FETCH_FAV_LOAN_REQUESTS_FAILURE,
	FETCH_MORE_FAV_LOAN_REQUESTS_REQUEST,
	FETCH_MORE_FAV_LOAN_REQUESTS_SUCCESS,
	FETCH_MORE_FAV_LOAN_REQUESTS_FAILURE,
	FETCH_CLEAR_FAV_LOAN_REQUESTS_REQUEST,
	FETCH_CLEAR_FAV_LOAN_REQUESTS_SUCCESS,
	FETCH_CLEAR_FAV_LOAN_REQUESTS_FAILURE,
	FETCH_YODLEE_DATA_AGGREGATION_REQUEST,
	FETCH_YODLEE_DATA_AGGREGATION_SUCCESS,
	FETCH_YODLEE_DATA_AGGREGATION_FAILURE,
	FETCH_BORROWER_COMPLETED_LOANS_HISTORY_REQUEST,
	FETCH_BORROWER_COMPLETED_LOANS_HISTORY_SUCCESS,
	FETCH_BORROWER_COMPLETED_LOANS_HISTORY_FAILURE,
	FETCH_FAVORITE_LOAN_REQUEST,
	FETCH_FAVORITE_LOAN_SUCCESS,
	FETCH_FAVORITE_LOAN_FAILURE,
	FETCH_UNFAVORITE_LOAN_REQUEST,
	FETCH_UNFAVORITE_LOAN_SUCCESS,
	FETCH_UNFAVORITE_LOAN_FAILURE,
	FETCH_DISMISS_LOAN_REQUEST,
	FETCH_DISMISS_LOAN_SUCCESS,
	FETCH_DISMISS_LOAN_FAILURE,
	FETCH_OFFER_LOAN_REQUEST,
	FETCH_OFFER_LOAN_SUCCESS,
	FETCH_OFFER_LOAN_FAILURE,
	UPDATE_OFFER_LOANS,
	FETCH_LENME_PREDICTION_REQUEST,
	FETCH_LENME_PREDICTION_SUCCESS,
	FETCH_LENME_PREDICTION_FAILURE,
	SEND_LENME_PREDICTION_RECEIPT_REQUEST,
	SEND_LENME_PREDICTION_RECEIPT_SUCCESS,
	SEND_LENME_PREDICTION_RECEIPT_FAILURE,
} from "./types";

// Initial State
const initialState = {
	loanRequests: {
		loading: true,
		data: {},
		error: "",
		paginate: {
			loading: false,
		},
	},
	filterLoanRequests: {
		loading: false,
		error: "",
	},
	favLoanRequests: {
		loading: true,
		data: {},
		error: "",
		paginate: {
			loading: false,
		},
	},
	clearAllFavLoans: {
		loading: false,
		error: "",
	},
	yodleeDataAggregation: {
		loading: false,
		data: {},
		error: "",
	},
	borrowerCompletedLoansHistory: {
		loading: false,
		data: {},
		error: "",
	},
	favoriteLoan: {
		loading: false,
		error: "",
	},
	unfavoriteLoan: {
		loading: false,
		error: "",
	},
	dismissLoan: {
		loading: false,
		error: "",
	},
	offerLoan: {
		loading: false,
		error: "",
	},
	prediction: {
		loading: false,
		data: {},
		error: "",
	},
	sendReceipt: {
		loading: false,
		data: {},
		error: "",
	}
};

// Reducer
const reducer = (state = initialState, action) => {
	switch (action.type) {
		// Fetch Loan Requests
		case FETCH_LOAN_REQUESTS_REQUEST:
			return {
				...state,
				loanRequests: { ...state.loanRequests, loading: true },
			};
		case FETCH_LOAN_REQUESTS_SUCCESS:
			return {
				...state,
				loanRequests: { ...state.loanRequests, loading: false, data: action.payload },
			};
		case FETCH_LOAN_REQUESTS_FAILURE:
			return {
				...state,
				loanRequests: { ...state.loanRequests, loading: false, error: action.payload },
			};

		// Fetch More Loan Requests
		case FETCH_MORE_LOAN_REQUESTS_REQUEST:
			return {
				...state,
				loanRequests: { ...state.loanRequests, paginate: { loading: true } },
			};
		case FETCH_MORE_LOAN_REQUESTS_SUCCESS:
			return {
				...state,
				loanRequests: {
					...state.loanRequests,
					paginate: { loading: false },
					data: {
						...action.payload,
						results: [...state.loanRequests.data.results, ...action.payload.results],
					},
				},
			};
		case FETCH_MORE_LOAN_REQUESTS_FAILURE:
			return {
				...state,
				loanRequests: {
					...state.loanRequests,
					paginate: { loading: false },
					error: action.payload,
				},
			};

		// Fetch Filtered Loan Requests
		case FETCH_FILTERED_LOAN_REQUESTS_REQUEST:
			return {
				...state,
				loanRequests: { ...state.loanRequests, loading: true },
				filterLoanRequests: { ...state.filterLoanRequests, loading: true },
			};
		case FETCH_FILTERED_LOAN_REQUESTS_SUCCESS:
			return {
				...state,
				loanRequests: { ...state.loanRequests, loading: false, data: action.payload },
				filterLoanRequests: { ...state.filterLoanRequests, loading: false },
			};
		case FETCH_FILTERED_LOAN_REQUESTS_FAILURE:
			return {
				...state,
				loanRequests: { ...state.loanRequests, loading: false },
				filterLoanRequests: { ...state.filterLoanRequests, loading: false, error: action.payload },
			};

		// Fetch Fav Loan Requests
		case FETCH_FAV_LOAN_REQUESTS_REQUEST:
			return {
				...state,
				favLoanRequests: { ...state.favLoanRequests, loading: true },
			};
		case FETCH_FAV_LOAN_REQUESTS_SUCCESS:
			return {
				...state,
				favLoanRequests: { ...state.favLoanRequests, loading: false, data: action.payload },
			};
		case FETCH_FAV_LOAN_REQUESTS_FAILURE:
			return {
				...state,
				favLoanRequests: { ...state.favLoanRequests, loading: false, error: action.payload },
			};

		// Fetch More Fav Loan Requests
		case FETCH_MORE_FAV_LOAN_REQUESTS_REQUEST:
			return {
				...state,
				favLoanRequests: { ...state.favLoanRequests, paginate: { loading: true } },
			};
		case FETCH_MORE_FAV_LOAN_REQUESTS_SUCCESS:
			return {
				...state,
				favLoanRequests: {
					...state.favLoanRequests,
					paginate: { loading: false },
					data: {
						...action.payload,
						results: [...state.favLoanRequests.data.results, ...action.payload.results],
					},
				},
			};
		case FETCH_MORE_FAV_LOAN_REQUESTS_FAILURE:
			return {
				...state,
				favLoanRequests: {
					...state.favLoanRequests,
					paginate: { loading: false },
					error: action.payload,
				},
			};

		// Fetch Clear Fav Loan Requests
		case FETCH_CLEAR_FAV_LOAN_REQUESTS_REQUEST:
			return {
				...state,
				clearAllFavLoans: { ...state.clearAllFavLoans, loading: true },
			};
		case FETCH_CLEAR_FAV_LOAN_REQUESTS_SUCCESS:
			return {
				...state,
				clearAllFavLoans: { ...state.clearAllFavLoans, loading: false },
			};
		case FETCH_CLEAR_FAV_LOAN_REQUESTS_FAILURE:
			return {
				...state,
				clearAllFavLoans: { ...state.clearAllFavLoans, loading: false, error: action.payload },
			};

		// Fetch Yodlee Data Aggregation Requests
		case FETCH_YODLEE_DATA_AGGREGATION_REQUEST:
			return {
				...state,
				yodleeDataAggregation: { ...state.yodleeDataAggregation, loading: true },
			};
		case FETCH_YODLEE_DATA_AGGREGATION_SUCCESS:
			return {
				...state,
				yodleeDataAggregation: { ...state.yodleeDataAggregation, loading: false, data: action.payload },
			};
		case FETCH_YODLEE_DATA_AGGREGATION_FAILURE:
			return {
				...state,
				yodleeDataAggregation: { ...state.yodleeDataAggregation, loading: false, error: action.payload },
			};

		// Fetch Borrower Completed Loans History
		case FETCH_BORROWER_COMPLETED_LOANS_HISTORY_REQUEST:
			return {
				...state,
				borrowerCompletedLoansHistory: { ...state.borrowerCompletedLoansHistory, loading: true },
			};
		case FETCH_BORROWER_COMPLETED_LOANS_HISTORY_SUCCESS:
			return {
				...state,
				borrowerCompletedLoansHistory: {
					...state.borrowerCompletedLoansHistory,
					loading: false,
					data: action.payload,
				},
			};
		case FETCH_BORROWER_COMPLETED_LOANS_HISTORY_FAILURE:
			return {
				...state,
				borrowerCompletedLoansHistory: {
					...state.borrowerCompletedLoansHistory,
					loading: false,
					error: action.payload,
				},
			};

		// Fetch Favorite Loan Request
		case FETCH_FAVORITE_LOAN_REQUEST:
			return {
				...state,
				favoriteLoan: { ...state.favoriteLoan, loading: true },
			};
		case FETCH_FAVORITE_LOAN_SUCCESS:
			function handleFavoriteLoanRequest() {
				const requests = [...state.loanRequests.data.results];
				const request = requests.find((item) => item.id === action.requestId);
				const index = requests.indexOf(request);
				requests[index].fav_loan_request_id = action.favLoanRequestId;

				return requests;
			}

			return {
				...state,
				loanRequests: {
					...state.loanRequests,
					data: { ...state.loanRequests.data, results: handleFavoriteLoanRequest() },
				},
				favoriteLoan: { ...state.favoriteLoan, loading: false },
			};
		case FETCH_FAVORITE_LOAN_FAILURE:
			return {
				...state,
				favoriteLoan: { ...state.favoriteLoan, loading: false, error: action.payload },
			};

		// Fetch Unfavorite Loan Request
		case FETCH_UNFAVORITE_LOAN_REQUEST:
			return {
				...state,
				unfavoriteLoan: { ...state.unfavoriteLoan, loading: true },
			};
		case FETCH_UNFAVORITE_LOAN_SUCCESS:
			function handleUnfavoriteLoanRequest() {
				const requests = [...state.loanRequests.data.results];
				const request = requests.find((item) => item.id === action.requestId);
				const index = requests.indexOf(request);
				requests[index].fav_loan_request_id = null;

				return requests;
			}

			function handleFilterFavoriteLoanRequests() {
				console.log('state.favLoanRequests.data.results ', state.favLoanRequests.data.results)
				const requests = [...state.favLoanRequests.data.results];
				return requests.filter((item) => item.id !== action.requestId);
			}

			return {
				...state,
				loanRequests: {
					...state.loanRequests,
					data: { ...state.loanRequests.data, results: handleUnfavoriteLoanRequest() },
				},
				favLoanRequests: {
					...state.favLoanRequests,
					data: { ...state.favLoanRequests.data, results: handleFilterFavoriteLoanRequests() },
				},
				unfavoriteLoan: { ...state.unfavoriteLoan, loading: false },
			};
		case FETCH_UNFAVORITE_LOAN_FAILURE:
			return {
				...state,
				unfavoriteLoan: { ...state.unfavoriteLoan, loading: false, error: action.payload },
			};

		// Fetch Dismiss Loan Request
		case FETCH_DISMISS_LOAN_REQUEST:
			function handleDissmissLoanRequest() {
				if (state.loanRequests.data.results) {
					return state.loanRequests.data.results.filter((item) => item.id !== action.requestId);
				}

				return [];
			}

			function handleDissmissFavLoanRequest() {
				if (state.favLoanRequests.data.results) {
					return state.favLoanRequests.data.results.filter((item) => item.id !== action.requestId);
				}

				return [];
			}

			return {
				...state,
				loanRequests: {
					...state.loanRequests,
					data: {
						...state.loanRequests.data,
						results: handleDissmissLoanRequest(),
					},
				},
				favLoanRequests: {
					...state.favLoanRequests,
					data: {
						...state.favLoanRequests.data,
						results: handleDissmissFavLoanRequest(),
					},
				},
				dismissLoan: { ...state.dismissLoan, loading: true },
			};
		case FETCH_DISMISS_LOAN_SUCCESS:
			return {
				...state,
				dismissLoan: { ...state.dismissLoan, loading: false },
			};
		case FETCH_DISMISS_LOAN_FAILURE:
			return {
				...state,
				dismissLoan: { ...state.dismissLoan, loading: false, error: action.payload },
			};

		// Fetch Offer Loan Request
		case FETCH_OFFER_LOAN_REQUEST:
			return {
				...state,
				offerLoan: { ...state.offerLoan, loading: true },
			};
		case FETCH_OFFER_LOAN_SUCCESS:
			return {
				...state,
				offerLoan: { ...state.offerLoan, loading: false },
			};
		case FETCH_OFFER_LOAN_FAILURE:
			return {
				...state,
				offerLoan: { ...state.offerLoan, loading: false, error: action.payload },
			};

		// Update Offer Loans
		case UPDATE_OFFER_LOANS:
			function handleUpdateOfferLoans() {
				if (state.loanRequests.data.results) {
					return state.loanRequests.data.results.filter((item) => item.id !== action.requestId);
				}

				return [];
			}

			function handleUpdateOfferFavLoana() {
				if (state.favLoanRequests.data.results) {
					return state.favLoanRequests.data.results.filter((item) => item.id !== action.requestId);
				}

				return [];
			}

			return {
				...state,
				loanRequests: {
					...state.loanRequests,
					data: {
						...state.loanRequests.data,
						results: handleUpdateOfferLoans(),
					},
				},
				favLoanRequests: {
					...state.favLoanRequests,
					data: {
						...state.favLoanRequests.data,
						results: handleUpdateOfferFavLoana(),
					},
				},
			};

		// Fetch Lenme Prediction
		case FETCH_LENME_PREDICTION_REQUEST:
			return {
				...state,
				prediction: { ...state.prediction, loading: true },
			};
		case FETCH_LENME_PREDICTION_SUCCESS:
			return {
				...state,
				prediction: { ...state.prediction, loading: false, data: action.payload },
			};
		case FETCH_LENME_PREDICTION_FAILURE:
			return {
				...state,
				prediction: { ...state.prediction, loading: false, error: action.payload },
			};

		// Send Lenme Prediction Receipt
		case SEND_LENME_PREDICTION_RECEIPT_REQUEST:
			return {
				...state,
				sendReceipt: { ...state.sendReceipt, loading: true },
			};
		case SEND_LENME_PREDICTION_RECEIPT_SUCCESS:
			return {
				...state,
				sendReceipt: { ...state.sendReceipt, loading: false, data: action.payload },
			};
		case SEND_LENME_PREDICTION_RECEIPT_FAILURE:
			return {
				...state,
				sendReceipt: { ...state.sendReceipt, loading: false, error: action.payload },
			};
		default:
			return state;
	}
};

export default reducer;
