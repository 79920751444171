import React from "react";
import PropTypes from "prop-types";

// Import CSS
import "./LNAvatar.css";

function LNAvatar({ data, double, small, offerCard }) {
	const icon_01 = data[0].icon;
	const color_01 = data[0].color;
	const initials_01 = data[0].initials;

	const icon_02 = double ? data[1].icon : "";
	const color_02 = double ? data[1].color : "";

	// Helper Methods
	const getAvatar = (icon, color) => {
		// Uses Avatar icon and color defined in endpoint response to return proper file name
		let imageName = `Avatar_${icon}_${color}`;
		// If avatar === 0 (No Avatar assigned, show default avatar)
		if (icon === 0 || icon == undefined || icon > 3 ){
			imageName = "Avatar_1_4";
		} 
		return imageName;
	};

	const avatar_01 = require(`../../../img/avatars/${getAvatar(icon_01, color_01)}.svg`);
	const avatar_02 = double ? require(`../../../img/avatars/${getAvatar(icon_02, color_02)}.svg`) : "";

	// Render
	return (
		<>
			{/* Render single avatar with initials if available if not double */}
			{offerCard &&
				<div className="d-flex justify-content-center position-relative">
					<img 
						style={{width:64, height:64, marginLeft:0, marginRight: "35px",marginInlineStart:20}}
						src={typeof avatar_01 === "string" ? avatar_01 : avatar_01.default}
						alt={initials_01}
						className="avatar"
					/>
				{initials_01 && <div style={{fontSize:20, fontWeight:"bolder",marginRight: "-60px",}}>{initials_01}</div>}
				</div>
			}
			{!double && !offerCard &&(
				<div className="d-flex justify-content-center position-relative">
					<img
						src={typeof avatar_01 === "string" ? avatar_01 : avatar_01.default}
						alt={initials_01}
						className="avatar"
					/>
					{initials_01 && <div className="avatar-initials px-2">{initials_01}</div>}
				</div>
			)}

			{/* Render 2 overlapping avatars if double */}
			{double && !offerCard && (
				<div className={`d-flex position-relative double-avatars ${small ? "small-avatars" : ""}`}>
					<img
						src={typeof avatar_01 === "string" ? avatar_01 : avatar_01.default}
						alt="Loan Provider"
						className="avatar-double-1"
					/>
					<img
						src={typeof avatar_02 === "string" ? avatar_02 : avatar_02.default}
						alt="Loan Borrower"
						className="avatar-double-2"
					/>
				</div>
			)}
		</>
	);
}

// Prop Types
LNAvatar.propTypes = {
	data: PropTypes.array.isRequired,
	double: PropTypes.bool,
	small: PropTypes.bool,
};

export default LNAvatar;
