// Types
import {
	FETCH_HEADER_NOTIFICATIONS_DATA_REQUEST,
	FETCH_HEADER_NOTIFICATIONS_DATA_SUCCESS,
	FETCH_HEADER_NOTIFICATIONS_DATA_FAILURE,
	FETCH_NOTIFICATIONS_DATA_REQUEST,
	FETCH_NOTIFICATIONS_DATA_SUCCESS,
	FETCH_NOTIFICATIONS_DATA_FAILURE,
	FETCH_MORE_NOTIFICATIONS_DATA_REQUEST,
	FETCH_MORE_NOTIFICATIONS_DATA_SUCCESS,
	FETCH_MORE_NOTIFICATIONS_DATA_FAILURE,
} from "./types";

// Initial State
const initialState = {
	headerNotificationsData: {
		loading: true,
		notifications: [],
		error: "",
	},
	notificationsData: {
		loading: true,
		notifications: [],
		error: "",
		loadingMore: false,
	},
};

// Reducer
const reducer = (state = initialState, action) => {
	switch (action.type) {
		// Fetch Header Notifications Data
		case FETCH_HEADER_NOTIFICATIONS_DATA_REQUEST:
			return {
				...state,
				headerNotificationsData: { ...state.headerNotificationsData, loading: true },
			};
		case FETCH_HEADER_NOTIFICATIONS_DATA_SUCCESS:
			return {
				...state,
				headerNotificationsData: {
					...state.headerNotificationsData,
					loading: false,
					notifications: action.payload,
				},
			};
		case FETCH_HEADER_NOTIFICATIONS_DATA_FAILURE:
			return {
				...state,
				headerNotificationsData: { ...state.headerNotificationsData, loading: false, error: action.payload },
			};

		// Fetch Notifications Data
		case FETCH_NOTIFICATIONS_DATA_REQUEST:
			return {
				...state,
				notificationsData: { ...state.notificationsData, loading: true },
			};
		case FETCH_NOTIFICATIONS_DATA_SUCCESS:
			return {
				...state,
				notificationsData: { ...state.notificationsData, loading: false, notifications: action.payload },
			};
		case FETCH_NOTIFICATIONS_DATA_FAILURE:
			return {
				...state,
				notificationsData: { ...state.notificationsData, loading: false, error: action.payload },
			};

		// Fetch More Notifications Data
		case FETCH_MORE_NOTIFICATIONS_DATA_REQUEST:
			return {
				...state,
				notificationsData: {
					...state.notificationsData,
					loadingMore: true,
				},
			};
		case FETCH_MORE_NOTIFICATIONS_DATA_SUCCESS:
			return {
				...state,
				notificationsData: {
					...state.notificationsData,
					loadingMore: false,
					notifications: {
						...action.payload,
						results: [...state.notificationsData.notifications.results, ...action.payload.results],
					},
				},
			};
		case FETCH_MORE_NOTIFICATIONS_DATA_FAILURE:
			return {
				...state,
				notificationsData: {
					...state.notificationsData,
					loadingMore: false,
					error: action.payload,
				},
			};

		default:
			return state;
	}
};

export default reducer;
