import React from "react";
import { useDispatch } from "react-redux";
import { hideToast } from "../../../redux";

// Images
import CloseIcon from "../../../img/icons/white/close-icon.svg";
import InfoIcon from "../../../img/icons/white/info-icon.svg";
import SuccessIcon from "../../../img/icons/white/success-icon.svg";

// CSS
import "./LNToast.css";

function LNToast({ index, type, title, body, button, onButtonClick = () => {} }) {
	const dispatch = useDispatch();

	// Helper Methods
	const handleColorCssClass = () => {
		switch (type) {
			case "info":
				return "ln-toast-info";
			case "success":
				return "ln-toast-success";
			case "danger":
				return "ln-toast-danger";
			case "warning":
				return "ln-toast-warning";
			default:
				return "";
		}
	};

	const handleLabelImgPath = () => {
		switch (type) {
			case "info":
			case "danger":
			case "warning":
				return InfoIcon;
			case "success":
				return SuccessIcon;
			default:
				return "";
		}
	};

	return (
		<div className={`ln-toast d-flex align-items-start py-2 rounded-sm ${handleColorCssClass()}`}>
			{type && (
				<div className="ln-toast-label pl-3 py-1">
					<img src={handleLabelImgPath()} alt="..." />
				</div>
			)}

			<div className="flex-grow-1 px-3 py-1">
				{title && <div className="ln-toast-title">{title}</div>}
				<div className="ln-toast-body py-1">{body}</div>
			</div>

			{button && (
				<button className="ln-toast-btn" onClick={onButtonClick}>
					{button}
				</button>
			)}

			<div className="ln-toast-close px-3 py-1" onClick={() => dispatch(hideToast(index))}>
				<img src={CloseIcon} alt="x" />
			</div>
		</div>
	);
}

export default LNToast;
