// Fetch Header Notifications Data
export const FETCH_HEADER_NOTIFICATIONS_DATA_REQUEST = "FETCH_HEADER_NOTIFICATIONS_DATA_REQUEST";
export const FETCH_HEADER_NOTIFICATIONS_DATA_SUCCESS = "FETCH_HEADER_NOTIFICATIONS_DATA_SUCCESS";
export const FETCH_HEADER_NOTIFICATIONS_DATA_FAILURE = "FETCH_HEADER_NOTIFICATIONS_DATA_FAILURE";

// Fetch Notifications Data
export const FETCH_NOTIFICATIONS_DATA_REQUEST = "FETCH_NOTIFICATIONS_DATA_REQUEST";
export const FETCH_NOTIFICATIONS_DATA_SUCCESS = "FETCH_NOTIFICATIONS_DATA_SUCCESS";
export const FETCH_NOTIFICATIONS_DATA_FAILURE = "FETCH_NOTIFICATIONS_DATA_FAILURE";

// Fetch More Notifications Data
export const FETCH_MORE_NOTIFICATIONS_DATA_REQUEST = "FETCH_MORE_NOTIFICATIONS_DATA_REQUEST";
export const FETCH_MORE_NOTIFICATIONS_DATA_SUCCESS = "FETCH_MORE_NOTIFICATIONS_DATA_SUCCESS";
export const FETCH_MORE_NOTIFICATIONS_DATA_FAILURE = "FETCH_MORE_NOTIFICATIONS_DATA_FAILURE";
