import React, { useEffect, useState } from 'react'

// type AutomationLoanTypeTagProps = {
//   value: string;
//   style?: React.CSSProperties;
// };

const TransactionsHistoryTags = ({ type, status }) => {
  const [color, setColor] = useState('#DEDEDE')
  const [textColor, setTextColor] = useState('#1A1A1A')
  const [displayValue, setDisplayValue] = useState('')
  useEffect(() => {
    if (status === 'pending') {
      setDisplayValue('Pending');
      setTextColor("#FFF");
      setColor('#FF977E');
    }
    else if (status === 'processed') {
      setDisplayValue('Processed');
      setTextColor("#FFF");
      setColor('#38B7FF');
    }
    else if (status === 'failed') {
      setDisplayValue('Failed');
      setTextColor("#FFF");
      setColor('#FF6466');
    }

    if (type === 'add_money') {
      setDisplayValue('Add Money');
      setTextColor("#FFF");
      setColor('#089C8E');
    }
    else if (type === 'withdrawal') {
      setDisplayValue('Withdraw Money');
      setTextColor("#FFF");
      setColor('#6462FF');
    }
    else if (type === 'fund_loans') {
      setDisplayValue('Fund Loan');
      setTextColor("#FFF");
      setColor('#35C2CF');
    }
    else if (type === 'monthly_payments') {
      setDisplayValue('Monthly Payments');
      setTextColor("#FFF");
      setColor('#4EA4DE');
    }
    else if (type === 'collection') {
      setDisplayValue('Collection');
      setTextColor("#FFF");
      setColor('#4EA4DE');
    }
    
  }, [type, status])
  return (
    <div style={{
        display: 'flex', width: 'fit-content', height: '24px', padding: '3px 11px', 
        background: color, borderRadius: '100px', overflow: 'hidden', whiteSpace: 'nowrap', marginRight: '8px'
      }}
    >
      <p style={{ fontSize: '12px', fontWeight: 500,fontStyle: 'normal', fontFamily: 'Poppins', width: '100%', color: textColor }}>{displayValue}</p>
    </div>
  )
}

export default TransactionsHistoryTags