// Types
import {
	FETCH_FEED_DATA_REQUEST,
	FETCH_FEED_DATA_SUCCESS,
	FETCH_FEED_DATA_FAILURE,
	FETCH_FEED_NEXT_REQUEST,
	FETCH_FEED_NEXT_SUCCESS,
	FETCH_FEED_NEXT_FAILURE,
} from "./types";

// Initial State
const initialState = {
	loading: true,
	feed: {},
	error: "",
	paginate: {
		loading: false,
	},
};

// Reducer
const reducer = (state = initialState, action) => {
	switch (action.type) {
		// Fetch Feed Data
		case FETCH_FEED_DATA_REQUEST:
			return {
				...state,
				loading: true,
			};
		case FETCH_FEED_DATA_SUCCESS:
			return {
				...state,
				loading: false,
				feed: action.payload,
			};
		case FETCH_FEED_DATA_FAILURE:
			return {
				...state,
				loading: false,
				error: action.payload,
			};

		// Fetch Feed Next
		case FETCH_FEED_NEXT_REQUEST:
			return {
				...state,
				paginate: { loading: true },
			};
		case FETCH_FEED_NEXT_SUCCESS:
			return {
				...state,
				paginate: { loading: false },
				feed: { ...action.payload, results: [...state.feed.results, ...action.payload.results] },
			};
		case FETCH_FEED_NEXT_FAILURE:
			return {
				...state,
				paginate: { loading: false },
				error: action.payload,
			};

		default:
			return state;
	}
};

export default reducer;
