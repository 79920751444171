import React, { useState } from "react";
import PropTypes from "prop-types";

// Import Bootstrap Components
import { Modal } from "react-bootstrap";

// Import Images
import RightArrowBold from "../../img/icons/black/arrow-right-bold.svg";

// Import CSS
import "./whiteBox.css";

function WhiteBox({ text, modalHeaderText, modalTitle, modalText, modalButttonText }) {
	const [modalVisible, setModalVisible] = useState(false);

	const showModal = () => {
		setModalVisible(true);
	};

	const hideModal = () => {
		setModalVisible(false);
	};

	return (
		<>
			<div onClick={showModal} className="white-box">
				<span>{text}</span>
				<img src={RightArrowBold} alt=">" />
			</div>

			<Modal show={modalVisible} onHide={hideModal} dialogClassName="white-box-modal">
				<Modal.Header>
					<div>{modalHeaderText}</div>
					<button onClick={hideModal} className="btn">
						Close
					</button>
				</Modal.Header>

				<Modal.Body>
					<div className="modal-title">{modalTitle}</div>
					<div className="modal-text">{modalText.replace(/<\/?[^>]+(>|$)/g, "")}</div>
				</Modal.Body>

				<Modal.Footer>
					<button onClick={hideModal} className="btn btn-black btn-lg">
						{modalButttonText}
					</button>
				</Modal.Footer>
			</Modal>
		</>
	);
}

// Prop Types
WhiteBox.propTypes = {
	text: PropTypes.string.isRequired,
	modalHeaderText: PropTypes.string,
	modalTitle: PropTypes.string,
	modalText: PropTypes.string,
	modalButttonText: PropTypes.string,
};

export default WhiteBox;
