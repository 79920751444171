import React from "react";
import PropTypes from "prop-types";

// Images
import Icon from "../../../img/icons/muted/info.svg";

// CSS
import "./infoIcon.css";

function InfoIcon({ handleClick, width = 16, classes }) {
	return (
		<span onClick={handleClick} className={`pointer d-inline-block ${classes}`}>
			<img src={Icon} alt="i" width={width} />
		</span>
	);
}

// Prop Types
InfoIcon.propTypes = {
	handleClick: PropTypes.func,
	width: PropTypes.number,
	classes: PropTypes.string,
};

export default InfoIcon;
