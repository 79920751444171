import React from "react";
import PropTypes from "prop-types";

// Import CSS
import "./LNSelect.css";

const LNSelect = ({ size, optional, label, name, value, onChange, onBlur, defaultOption, options, error }) => {
	// Helper Method
	function handleClasses() {
		let classes = "form-control select";

		// Size
		if (size === "lg") classes += " form-control-lg";
		if (size === "sm") classes += " form-control-sm";

		// Optional
		if (optional) classes += " form-control-optional";

		// Error
		if (error) classes += " is-invalid";

		return classes;
	}

	return (
		<div className="form-group">
			{label && <label htmlFor={name}>{label}</label>}
			<select name={name} value={value} onChange={onChange} onBlur={onBlur} className={handleClasses()} id={name}>
				<option value={defaultOption.value} disabled={defaultOption.disabled}>
					{defaultOption.name}
				</option>

				{options &&
					options.map((option, index) => (
						<option key={index} value={option.value}>
							{option.name}
						</option>
					))}
			</select>
			{error && <div className="invalid-feedback">{error}</div>}
		</div>
	);
};

// Prop Types
LNSelect.propTypes = {
	size: PropTypes.string,
	optional: PropTypes.bool,
	label: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
	onBlur: PropTypes.func,
	defaultOption: PropTypes.object,
	options: PropTypes.array,
	error: PropTypes.string,
};

export default LNSelect;
