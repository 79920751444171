import http from "./httpService";

const appVersion = process.env.REACT_APP_API_VERSION;
const apiEndpoint = "/v" + appVersion + "/loan_automation/";

// get automation rules
export async function getAutomationRules() {

	return await http.get(apiEndpoint);
}

// post automation rules
export async function postAutomationRules(params) {

	return await http.post(apiEndpoint, params);
}

// put automation rules
export async function putAutomationRules(id, params) {

	return await http.put(apiEndpoint + id, params);
}

// delete automation rules
export async function deleteAutomationRules(id) {

	return await http.delete(apiEndpoint + id);
}