import { CountryRegionData } from "react-country-region-selector";

// Gets list of countries and parses into a label/value array
export function getCountryList() {
	const countries = CountryRegionData.map(([country_name, country_code]) => ({
		name: country_name,
		value: country_code,
	}));

	return countries;
}
