import React from "react";
import PropTypes from "prop-types";

// Import Services

// Import Utils

// Import Components

// Import Images

// Import CSS
import "./LNCheckBox.css";

const LNCheckBox = ({ label, name, value, onChange, disabled }) => {
	// Render
	return (
		<div className="custom-control custom-checkbox">
			<input
				type="checkbox"
				name={name}
				className="custom-control-input"
				id={name}
				onChange={onChange}
				value={value}
				disabled={disabled}
			/>
			<label className="custom-control-label" htmlFor={name}>
				{label}
			</label>
		</div>
	);
};

// Prop Types
LNCheckBox.propTypes = {
	label: PropTypes.string,
	name: PropTypes.string,
	value: PropTypes.string,
	onChange: PropTypes.func,
};

export default LNCheckBox;
