import http from "./httpService";

const appVersion = process.env.REACT_APP_API_VERSION;
const apiEndpoint = "/v" + appVersion + "/user/";

// Get User
export async function getUser() {
	return await http.get(apiEndpoint);
}

// Get Current Balance
export async function getCurrentBalance() {
	return await http.get(apiEndpoint + "balance/");
}

// Get Avg of Return
export async function getAvgOfReturn() {
	return await http.get(apiEndpoint + "average_rate_return/");
}

// Get Current Loan
export async function getCurrentLoan() {
	return await http.get(apiEndpoint + "current_loan/");
}

// Get Total Invested
export async function getTotalInvested() {
	return await http.get(apiEndpoint + "invested/");
}

// Get Total Earned
export async function getTotalEarned() {
	return await http.get(apiEndpoint + "total_earned/");
}

// Get the aggregation data
export async function getAggregationData() {
	return await http.get("/v" + appVersion + "/analytics_dashboard/investors/agg_data/");
}

// Get Yodlee data aggregation
export async function getYodleeDataAggregation(user_id) {
	return await http.get(apiEndpoint + user_id + "/user_yodlee_data_aggs/");
}

// Get User's balance
export async function getUserBalance() {
	return await http.get(apiEndpoint + "balance/");
}

// Get User's company data
export async function getCompany() {
	return await http.get("enterprise/v" + appVersion + "/profile/");
}

// Get User's lending license info
export async function getLicense() {
	return await http.get("enterprise/v" + appVersion + "/license/");
}

// Get a document by it's ID
export async function getFile(id) {
	return await http.get("enterprise/v" + appVersion + "/enterprise_file_upload/" + id);
}

// Edit a User's Personal Info
export async function updateUserProfile(updated_data) {
	return await http.post(apiEndpoint + "update_profile/", updated_data);
}

// Edit a User's Company
export async function updateUserCompany(updated_data) {
	return await http.put("enterprise/v" + appVersion + "/profile/update/", updated_data);
}

export async function updateDeviceInfo(params) {
	return await http.post("/v" + appVersion + "/devices/", params);
}

export default {
	getUser,
	getCurrentBalance,
	getAvgOfReturn,
	getCurrentLoan,
	getTotalInvested,
	getTotalEarned,
	getAggregationData,
	getYodleeDataAggregation,
	getUserBalance,
	getCompany,
	getLicense,
	getFile,
	updateUserProfile,
	updateUserCompany,
};
