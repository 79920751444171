import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import moment from "moment";

// Import Images
import InfoIcon from "../../img/icons/info-icon.svg";

// Import CSS
import "./notificationItem.css";

const NotificationItem = ({ notification }) => {
	// Helper Methods
	const isMoreThanOneDayAgo = () => {
		const a = moment();
		const b = moment(notification.created);
		const diff = a.diff(b, "days");

		if (diff > 0) return false;
		return true;
	};

	// Render
	return (
		<div className="notification-item mt-3">
			{isMoreThanOneDayAgo() && <div className="new-label">NEW</div>}

			<div className="d-flex">
				<div className="mr-3">
					<img src={InfoIcon} alt="Info" />
				</div>
				<div>
					<p>{notification.content}</p>
					<div className="text-right d-none">
						<Link to="/" className="notification-direction-link">
							Verify now
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
};

// Prop Types
NotificationItem.propTypes = {
	notification: PropTypes.object.isRequired,
};

export default NotificationItem;
