import { init as sentry_init } from "./services/logService";
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import {
	BrowserRouter as Router,
  } from 'react-router-dom';
import history from "./history";

// Redux
import { Provider } from "react-redux";
import store from "./redux/store";

// CSS
import 'swiper/css';
import "./styles/bootstrap/bootstrap.css";
import "./styles/bootstrap-overrides.css";
import "./styles/masterstyle.css";
import "./styles/style.css";

// Logger
sentry_init();

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
//   <React.StrictMode>
	<Router history={history}>
		<Provider store={store}>
    		<App />
		</Provider>
	</Router>
//   </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
