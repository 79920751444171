import supportService from "../../services/supportService";

// Types
import {
	FETCH_HELP_ARTICLES_DATA_REQUEST,
	FETCH_HELP_ARTICLES_DATA_SUCCESS,
	FETCH_HELP_ARTICLES_DATA_FAILURE,
	FILTER_HELP_ARTICLES_DATA,
} from "./types";

// Fetch Help Articles Data
export const fetchHelpArticlesDataRequest = () => {
	return {
		type: FETCH_HELP_ARTICLES_DATA_REQUEST,
	};
};

export const fetchHelpArticlesDataSuccess = data => {
	return {
		type: FETCH_HELP_ARTICLES_DATA_SUCCESS,
		payload: data,
	};
};

export const fetchHelpArticlesDataFailure = error => {
	return {
		type: FETCH_HELP_ARTICLES_DATA_FAILURE,
		payload: error,
	};
};

export const fetchHelpArticlesData = () => {
	return async function(dispatch) {
		try {
			dispatch(fetchHelpArticlesDataRequest());

			const data = await supportService.getHelpArticles();
			dispatch(fetchHelpArticlesDataSuccess(data));
		} catch (ex) {
			dispatch(fetchHelpArticlesDataFailure(ex));
			console.log("Error: ", ex);
		}
	};
};

// Filter Help Articles Data
export const filterHelpArticlesData = query => {
	return {
		type: FILTER_HELP_ARTICLES_DATA,
		payload: query,
	};
};
