import http from "./httpService";

const appVersion = process.env.REACT_APP_API_VERSION;
const apiEndpoint = "/v" + appVersion + "/yodlee-providers/";

// Get Favourite Bank Providers
export async function getFavouriteBankProviders() {
	return await http.get(apiEndpoint + "?is_favourite=true");
}

// Get Bank Providers
export async function getBankProviders(query) {
	return await http.get(apiEndpoint + "?search=" + query);
}

// Get Next Bank Providers
export async function getNextBankProviders(next) {
	return await http.get(next);
}

// Get Prev Bank Providers
export async function getPrevBankProviders(prev) {
	return await http.get(prev);
}

// Check if Fiserv account added
export async function checkIfFiservAccountAdded(fiLoginAcctId, providerId) {
	return await http.post("/v" + appVersion + "/fiserv-fundingsource/update_fiserv_account/", {
		FILoginId: fiLoginAcctId,
		FIId: providerId,
	});
}

// Check if resolution widget succeed
export async function updateFiservAccount303Flow(fiLoginAcctId, providerId) {
	return await http.post("/v" + appVersion + "/fiserv-fundingsource/update_fiserv_account_303_flow/", {
		FILoginId: fiLoginAcctId,
		FIId: providerId,
	});
}

// fetch transactions history
export async function fetchTransactionsHistory() {
	return await http.get("/v" + appVersion + "/transfer_history/");
}

export default {
	getFavouriteBankProviders,
	getBankProviders,
	getNextBankProviders,
	getPrevBankProviders,
	checkIfFiservAccountAdded,
	updateFiservAccount303Flow,
};
