// Types
import {
	FETCH_FAVOURITE_BANK_PROVIDERS_REQUEST,
	FETCH_FAVOURITE_BANK_PROVIDERS_SUCCESS,
	FETCH_FAVOURITE_BANK_PROVIDERS_FAILURE,
	FETCH_BANK_PROVIDERS_REQUEST,
	FETCH_BANK_PROVIDERS_SUCCESS,
	FETCH_BANK_PROVIDERS_FAILURE,
	FETCH_NEXT_BANK_PROVIDERS_REQUEST,
	FETCH_NEXT_BANK_PROVIDERS_SUCCESS,
	FETCH_NEXT_BANK_PROVIDERS_FAILURE,
	FETCH_PREV_BANK_PROVIDERS_REQUEST,
	FETCH_PREV_BANK_PROVIDERS_SUCCESS,
	FETCH_PREV_BANK_PROVIDERS_FAILURE,
} from "./types";

// Initial State
const initialState = {
	providersData: {
		loading: true,
		providers: {},
		error: "",
	},
};

// Reducer
const reducer = (state = initialState, action) => {
	switch (action.type) {
		// Fetch Favourite Bank Providers
		case FETCH_FAVOURITE_BANK_PROVIDERS_REQUEST:
			return {
				...state,
				providersData: { ...state.providersData, loading: true },
			};
		case FETCH_FAVOURITE_BANK_PROVIDERS_SUCCESS:
			return {
				...state,
				providersData: { ...state.providersData, loading: false, providers: action.payload },
			};
		case FETCH_FAVOURITE_BANK_PROVIDERS_FAILURE:
			return {
				...state,
				providersData: { ...state.providersData, loading: false, error: action.payload },
			};

		// Fetch Bank Providers
		case FETCH_BANK_PROVIDERS_REQUEST:
			return {
				...state,
				providersData: { ...state.providersData, loading: true },
			};
		case FETCH_BANK_PROVIDERS_SUCCESS:
			return {
				...state,
				providersData: { ...state.providersData, loading: false, providers: action.payload },
			};
		case FETCH_BANK_PROVIDERS_FAILURE:
			return {
				...state,
				providersData: { ...state.providersData, loading: false, error: action.payload },
			};

		// Fetch Next Bank Providers
		case FETCH_NEXT_BANK_PROVIDERS_REQUEST:
			return {
				...state,
				providersData: { ...state.providersData, loading: true },
			};
		case FETCH_NEXT_BANK_PROVIDERS_SUCCESS:
			return {
				...state,
				providersData: { ...state.providersData, loading: false, providers: action.payload },
			};
		case FETCH_NEXT_BANK_PROVIDERS_FAILURE:
			return {
				...state,
				providersData: { ...state.providersData, loading: false, error: action.payload },
			};

		// Fetch Prev Bank Providers
		case FETCH_PREV_BANK_PROVIDERS_REQUEST:
			return {
				...state,
				providersData: { ...state.providersData, loading: true },
			};
		case FETCH_PREV_BANK_PROVIDERS_SUCCESS:
			return {
				...state,
				providersData: { ...state.providersData, loading: false, providers: action.payload },
			};
		case FETCH_PREV_BANK_PROVIDERS_FAILURE:
			return {
				...state,
				providersData: { ...state.providersData, loading: false, error: action.payload },
			};

		default:
			return state;
	}
};

export default reducer;
