import React from "react";
import PropTypes from "prop-types";

// Images
import Icon from "../../../img/icons/muted/arrow-left-muted.svg";

// CSS
import "./backIcon.css";

function BackIcon({ handleClick, width = 16, classes }) {
	return (
		<div onClick={handleClick} className={`d-flex pointer align-items-center ${classes}`}>
			<img src={Icon} alt="<--" width={width} />
			<div className="pl-2">Back</div>
		</div>
	);
}

// Prop Types
BackIcon.propTypes = {
	handleClick: PropTypes.func,
	width: PropTypes.number,
	classes: PropTypes.string,
};

export default BackIcon;
